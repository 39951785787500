<template>
    <c-control-checkbox v-bind:label="field.label"
                        v-bind:name="id"
                        v-bind:options="options"
                        v-model="value"
                        v-if="field.widget === 'checkbox'"
                        v-on:update:modelValue="emitValue"
    />
    <c-control-number v-bind:label="field.label"
                      v-bind:name="id"
                      v-model="value"
                      v-if="field.widget === 'number'"
                      v-on:update:modelValue="emitValue"
    />
    <c-control-select v-bind:label="field.label"
                      v-bind:name="id"
                      v-bind:options="options"
                      v-model="value"
                      v-if="field.widget === 'select'"
                      v-on:update:modelValue="emitValue"
    />
    <c-control-text class-field="!bg-white"
                    v-bind:label="field.label"
                    v-bind:name="id"
                    v-model="value"
                    v-if="field.widget === 'text'"
                    v-on:update:modelValue="emitValue"
    />
    <c-control-text class-field="!bg-white pt-1" type="textarea"
                    v-bind:label="field.label"
                    v-bind:name="id"
                    v-model="value"
                    v-if="field.widget === 'textarea'"
                    v-on:update:modelValue="emitValue"
    />
    <c-control-toggle v-bind:label="field.label"
                      v-bind:name="id"
                      v-model="value"
                      v-if="field.widget === 'toggle'"
                      v-on:update:modelValue="emitValue"
    />
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue';
    import {
        CControlCheckbox,
        CControlNumber,
        CControlSelect,
        CControlText,
        CControlToggle,
    } from '@teamfurther/cinderblock';

    const emits = defineEmits(['update:modelValue']);
    const options = ref<Object[]>([]);
    const value = ref<any>(null);

    const props = defineProps({
        field: {
            required: true,
            type: Object,
        },
        id: {
            required: true,
            type: String,
        },
        modelValue: {
            default: null,
        },
    });

    function emitValue() {
        let returnValue = value.value;

        if (props.field.widget === 'checkbox') {
            returnValue = value.value.join('|');
        }

        emits('update:modelValue', returnValue);
    }

    function prepValues() {
        if (props.field.widget === 'checkbox') {
            value.value = props.modelValue ? props.modelValue.split('|') : [];
        } else if (props.field.widget === 'toggle') {
            value.value = (props.modelValue === '1');
        } else {
            value.value = props.modelValue;
        }
    }

    onMounted(async () => {
        prepValues();

        if (props.field.options) {
            props.field.options.forEach(option => {
                options.value.push({
                    label: Object.values(option)[0],
                    value: Object.keys(option)[0],
                });
            });
        }
    });

    watch(() => props.modelValue, () => {
        prepValues();
    });
</script>