<template>
    <div class="container px-1 py-5">
        <h1 class="lg:text-3xl mb-5 md:mb-10 mr-10 text-2xl">{{ __('Update payment method') }}</h1>
        <div class="bg-white border border-slate-200 md:w-7/12 p-2 rounded">
            <StripeElements
                ref="stripeElements"
                v-bind:stripe-key="stripePk"
                v-if="stripeLoaded"
                v-slot="{ elements, instance }"
            >
                <span class="font-semibold text-xs">{{ __('Card number') }}</span>
                    <StripeElement
                        ref="cardElement"
                        type="cardNumber"
                        v-bind:elements="elements"
                        v-bind:options="{ classes: cardClasses, showIcon: true, style: cardStyles }"
                        v-on:blur="showCardNumberRing = false"
                        v-on:focus="showCardNumberRing = true"
                    />
                <div class="gap-4 grid grid-cols-2 mt-4">
                    <div>
                        <span class="font-semibold text-xs">{{ __('Expiration date') }}</span>
                        <StripeElement
                            type="cardExpiry"
                            v-bind:elements="elements"
                            v-bind:options="{  classes: cardClasses, placeholder: __('MM/YY'), style: cardStyles }"
                            v-on:blur="showCardExpiryRing = false"
                            v-on:focus="showCardExpiryRing = true"
                        />
                    </div>
                    <div>
                        <span class="font-semibold text-xs">{{ __('Security code') }}</span>
                        <StripeElement
                            type="cardCvc"
                            v-bind:elements="elements"
                            v-bind:options="{ classes: cardClasses, style: cardStyles }"
                            v-on:blur="showCardCvvRing = false"
                            v-on:focus="showCardCvvRing = true"
                        />
                    </div>
                </div>
            </StripeElements>

            <div class="mb-4 mt-10 mx-auto relative lg:w-2/3 md:w-full sm:w-2/3">
                <img class="relative z-10" src="/img/credit-card.png" />
                <span class="absolute duration-500 font-mono left-[8%] p-px lg:text-lg rounded text-white top-[50%] transition-all z-20"
                      v-bind:class="{ 'ring-2 ring-amber-500' : showCardNumberRing }"
                >
                    0000 0000 0000 0000
                </span>
                <span class="absolute duration-500 font-mono left-[29%] lg:text-sm p-px rounded text-white text-xs top-[69%] transition-all z-20"
                      v-bind:class="{ 'ring-2 ring-amber-500' : showCardExpiryRing }"
                >
                    00/00
                </span>
                <span class="absolute duration-500 font-mono italic left-[70%] lg:text-sm p-px rounded text-xs top-[49%] transition-all z-20"
                      v-bind:class="{ 'ring-2 ring-amber-500' : showCardCvvRing }"
                >
                    000
                </span>
            </div>

            <c-button class="bg-success mt-10 text-white w-full"
                      v-bind:disabled="!stripeLoaded || loading"
                      v-text="__('Update payment method')"
                      v-on:click="update"
            ></c-button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onBeforeMount, onMounted, ref } from 'vue';
    import { StripeElements, StripeElement } from 'vue-stripe-js'
    import { loadStripe } from '@stripe/stripe-js';
    import {
        CButton,
    } from '@teamfurther/cinderblock';

    import { useRequestStore } from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';
    import { useSubscriptionsStore }  from '../../stores/subscriptions';

    const __ = inject('__');

    const cardClasses = ref({
        base: 'bg-slate-50 p-2 rounded',
    });

    const cardElement = ref(null);

    const cardStyles = ref({
        base: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '16px',
            '::placeholder': {
                color: '#94a3b8',
            },
        },
        invalid: {
            iconColor: '#f87171',
            color: '#fca5a5',
            '::placeholder': {
                color: '#fca5a5',
            },
        }
    });

    const loading = ref<boolean>(false);
    const paymentIntent = ref();
    const requestStore = useRequestStore();
    const sessionStore = useSessionStore();
    const subscriptionsStore = useSubscriptionsStore();
    const showCardCvvRing = ref<boolean>(false);
    const showCardExpiryRing = ref<boolean>(false);
    const showCardNumberRing = ref<boolean>(false);
    const stripeElements = ref(null);
    const stripeLoaded = ref<boolean>(false);
    const stripePk = import.meta.env.VITE_STRIPE_KEY;
    const stripePromise = loadStripe(stripePk);
    const tenant = sessionStore.user.tenant;

    async function update() {
        loading.value = true;

        const { setupIntent, error } = await stripeElements.value.instance.confirmCardSetup(paymentIntent.value.client_secret, {
            payment_method: {
                card: cardElement.value.stripeElement,
                billing_details: {
                    address: {
                        city: tenant.city,
                        country: tenant.country,
                        line1: tenant.address_line_1,
                        line2: tenant.address_line_2,
                        postal_code: tenant.postal_code ?? null,
                        state: tenant.county ?? null,
                    },
                    email: tenant.email,
                    name: tenant.name,
                },
            },
        });

        if (error) {
            console.error(error);

            loading.value = false;
        } else {
            await requestStore.post('subscriptions/update-payment-method', {
                payment_method: setupIntent.payment_method,
            }).then(async () => {
                await sessionStore.refreshUser();

                requestStore.redirect({ name: 'subscriptions.billing' }, {
                    message: __('Payment method successfully updated.'),
                    status: 200,
                    title: __('Success'),
                });
            }).catch(async () => {
                loading.value = false;

                paymentIntent.value = await subscriptionsStore.getPaymentIntent();
            });
        }
    }

    onBeforeMount(() => {
        stripePromise.then(() => {
            stripeLoaded.value = true;
        });
    });

    onMounted(async () => {
        paymentIntent.value = await subscriptionsStore.getPaymentIntent();
    });
</script>