<template>
    <header class="bg-white border-b border-b-slate-200 pb-2" v-if="sessionStore && sessionStore.user?.id">
        <div class="bg-amber-500 p-1 text-center text-xs text-white"
            v-html="__('You are currently on a free trial. To continue using the software after :date, please subscribe on the :link page.', {
                date: DateTime.fromISO(sessionStore.user.tenant.trial_ends_at).toFormat(localizationStore.dateFormat),
                link: '<a class=\'underline\' href=\'/subscriptions/subscribe\'>' + __('Billing') + '</a>',
            })"
            v-if="
                !sessionStore.user.tenant?.is_subscribed
                && sessionStore.user.tenant?.is_on_generic_trial
                && can('subscriptions.manage')
            "
        />
        <div class="bg-amber-500 p-1 text-center text-xs text-white"
            v-html="__('You are currently on a free trial. To continue using the software after :date, ask your account manager to subscribe.', {
                date: DateTime.fromISO(sessionStore.user.tenant.trial_ends_at).toFormat(localizationStore.dateFormat),
            })"
            v-if="
                !sessionStore.user.tenant?.is_subscribed
                && sessionStore.user.tenant?.is_on_generic_trial
                && !can('subscriptions.manage')
            "
        />
        <div class="bg-error p-1 text-center text-xs text-white"
            v-html="__('You don\'t have an active subscription. To continue using the software, please subscribe on the :link page.', {
                link: '<a class=\'underline\' href=\'/subscriptions/subscribe\'>' + __('Billing') + '</a>',
            })"
            v-if="
                !sessionStore.user.tenant?.is_subscribed
                && !sessionStore.user.tenant?.is_on_generic_trial
                && can('subscriptions.manage')
            "
        />
        <div class="bg-error p-1 text-center text-xs text-white"
            v-html="__('You don\'t have an active subscription. To continue using the software, ask your account manager to subscribe.')"
            v-if="
                !sessionStore.user.tenant?.is_subscribed
                && !sessionStore.user.tenant?.is_on_generic_trial
                && !can('subscriptions.manage')
            "
        />
        <div class="container flex h-8 items-center justify-between mt-2 px-1 relative z-40">
            <router-link class="outline-0" v-bind:to="{ name : sessionStore.home }">
                <img class="h-5 shrink-0" src="/img/logo.svg" v-bind:alt="app.name" />
            </router-link>

            <c-navigation>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('tickets')"
                                   v-bind:to="{ name : 'tickets' }"
                                   v-if="can('tickets.index.all')"
                >
                    {{ __('Tickets' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('tickets')"
                                   v-bind:to="{ name : 'tickets.me' }"
                                   v-if="can('tickets.index.owned') && !can('tickets.index.all')"
                >
                    {{ __('Tickets' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('partners')"
                                   v-bind:to="{ name : 'partners' }"
                                   v-if="can('partners.index')"
                >
                    {{ __('Partners' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('employees')"
                                   v-bind:to="{ name : 'employees' }"
                                   v-if="can('users.index')"
                >
                    {{ __('Employees' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('devices')"
                                   v-bind:to="{ name : 'devices.pn' }"
                                   v-if="can('devices.index')"
                >
                    {{ __('Devices' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('inventory')"
                                   v-bind:to="{ name : 'inventory' }"
                                   v-if="can('inventory.index')"
                >
                    {{ __('Inventory' ) }}
                </c-navigation-item>
                <c-navigation-item router
                                   v-bind:active="$route.name && $route.name.startsWith('reports')"
                                   v-bind:to="{ name : 'reports' }"
                                   v-if="can('worksheets.inventory.index|worksheets.time.index')"
                >
                    {{ __('Reports' ) }}
                </c-navigation-item>
                <c-navigation-item class="lg:ml-5 shrink-0" dropdown-position="right" has-dropdown dusk="profile-menu">
                    <img class="h-6 inline mr-2 -mt-1 rounded w-6"
                         v-bind:src="sessionStore.user.avatar"
                         v-bind:alt="sessionStore.user.name"
                    />
                    <span class="text-sm">{{ sessionStore.user.name }}</span>
                    <template v-slot:dropdown>
                        <c-navigation-item class="hover:!bg-slate-50 text-sm" router v-bind:to="{ name : 'profile.user' }">
                            {{ __('Personal profile' ) }}
                        </c-navigation-item>
                        <c-navigation-item class="hover:!bg-slate-50 text-sm"
                                           router
                                           v-bind:to="{ name : 'profile.company' }"
                                           v-if="can('profile.company.show')"
                        >
                            {{ __('Company profile' ) }}
                        </c-navigation-item>
                        <c-navigation-item class="hover:!bg-slate-50 lg:border-t text-sm"
                                           router
                                           v-bind:to="{ name : 'subscriptions.billing' }"
                                           v-if="can('subscriptions.manage')"
                        >
                            {{ __('Billing' ) }}
                        </c-navigation-item>
                        <c-navigation-item class="flex items-center lg:border-t hover:!bg-slate-50 text-sm"
                                           dusk="logout-button"
                                           v-on:click="sessionStore.logUserOut"
                        >
                            <c-icon class="h-5 inline mr-2 stroke-slate-800 w-5" icon="log-out" /> {{ __('Logout' ) }}
                        </c-navigation-item>
                    </template>
                </c-navigation-item>
            </c-navigation>
        </div>
    </header>
</template>

<script setup lang="ts">
    import { DateTime } from 'luxon';
    import {
        CIcon,
        CNavigation,
        CNavigationItem,
    } from '@teamfurther/cinderblock';

    import { useLocalizationStore } from '../stores/localization';
    import { useSessionStore } from '../stores/session';

    const localizationStore = useLocalizationStore();
    const sessionStore = useSessionStore();
</script>
