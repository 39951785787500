import Repository from './Repository';

export default class PartnerSiteRepository extends Repository {
    protected basePath = 'partners/sites';

    protected messages = {
        destroy : this.__('Partner site successfully deleted.'),
        reorder: this.__('Partner site order changed successfully.'),
        store : this.__('Partner site successfully created.'),
        update : this.__('Partner site successfully updated.'),
    }

    protected redirects = {
        store: { name : 'partners.sites.edit', params: { site: '$id' } },
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on PartnerSiteRepository.');
    }

    public import(params: object = null): Promise {
        console.error('Import method does not exist on PartnerSiteRepository.');
    }

    public reorder(data: object) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/reorder'), data)
                .then((response : any) => {
                    this.request.message = this.messages.reorder;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }
}