<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-start justify-between mb-10 md:flex-row md:items-center">
            <div class="flex flex-col mb-5 md:mb-0 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Tickets') }}</h1>

                <c-control-text
                    class="grow-0 mb-5 md:max-w-[300px] md:mb-0 md:mr-10"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Start typing...')"
                    v-model="s"
                    v-on:keyup="search"
                />

                <div class="flex items-center justify-between">
                    <div class="relative">
                        <a class="cursor-pointer duration-500 flex items-center p-1 rounded text-sm transition-all"
                           v-bind:class="{ 'bg-highlight text-white' : Object.keys(filters).length }"
                           v-on:click="openFilters = true"
                        >
                            <c-icon class="h-5 mr-2 stroke-slate-800 w-5" icon="filter"
                                    v-bind:class="{ '!stroke-white' : Object.keys(filters).length }"
                            />
                            {{ __('Filter results') }}
                            <span class="bg-white flex font-bold h-4 items-center justify-center ml-1 rounded-full text-center text-highlight text-xs w-4"
                                  v-if="Object.keys(filters).length"
                            >
                                {{ Object.keys(filters).length }}
                            </span>
                        </a>
                        <div class="absolute bg-white border border-slate-200 hidden md:w-[500px] mt-1 rounded shadow-lg w-[calc(100vw-0.5rem)] z-30"
                             v-bind:class="{ '!block' : openFilters }"
                             v-on-click-outside="closeFilters"
                        >
                            <div class="flex">
                                <nav class="bg-slate-50 border-r border-slate-200 flex flex-col shrink-0 text-sm">
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'partner' }"
                                       v-on:click="openFilter = 'partner'"
                                    >
                                        {{ __('Partner') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'created_at' }"
                                       v-on:click="openFilter = 'created_at'"
                                    >
                                        {{ __('Ticket creation date') }}
                                    </a>
                                    <a class="cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'updated_at' }"
                                       v-on:click="openFilter = 'updated_at'"
                                    >
                                        {{ __('Ticket last modified date') }}
                                    </a>
                                </nav>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'partner' }"
                                >
                                    <c-control-autocomplete class="w-full"
                                                            icon="search"
                                                            label-field="name"
                                                            name="filters.partner"
                                                            v-bind:placeholder="__('Search by partner name or tax ID')"
                                                            v-bind:source-get="partnerGet"
                                                            v-bind:source-search="partnerSearch"
                                                            v-model="filtersPartner"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'created_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.created_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.created_at"
                                                         v-on:change="updateDateFilter('created_at')"
                                        />
                                    </div>
                                    <c-control-date name="filters.created_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.created_at"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'updated_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.updated_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.updated_at"
                                                         v-on:change="updateDateFilter('updated_at')"
                                        />
                                    </div>
                                    <c-control-date name="filters.updated_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.updated_at"
                                    />
                                </div>
                            </div>
                            <div class="border-t border-slate-200 flex justify-end p-2">
                                <c-button class="mr-2 p-1.5 text-error" v-on:click="resetFilters">
                                    {{ __('Reset filters') }}
                                </c-button>
                                <c-button class="bg-highlight p-1.5 shrink-0 text-white" v-on:click="filter">
                                    {{ __('Apply filters') }}
                                </c-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <c-button class="bg-highlight shrink-0 text-white"
                      router
                      v-bind:to="{ name : 'tickets.create' }"
                      v-if="can('tickets.store')"
            >
                + {{ __('Add ticket') }}
            </c-button>
        </div>
    </div>
    <div class="container px-1">
        <c-data-table responsive-breakpoint="lg"
                      v-bind:data="tableData"
                      v-if="tableData.items.length > 0"
                      v-on:sort-table="sortTable"
        >
            <template v-slot:item.oid="{ item }">
                #{{ item.values.oid }}
            </template>
            <template v-slot:item.partner="{ item }">
                {{ item.values.site.partner.name }}
            </template>
            <template v-slot:item.description="{ item }">
                {{
                    item.values.description?.length > 50
                        ? item.values.description.substring(0, 50) + '...'
                        : item.values.description
                }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ DateTime.fromISO(
                    item.values.created_at,
                    { locale : localizationStore.locale }
                ).toRelative() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <router-link class="cursor-pointer text-highlight text-xs"
                             v-bind:to="{ name : 'tickets.me.edit', params : { ticket : item.values.id } }"
                             v-if="can('tickets.update.all|tickets.update.owned')"
                >
                    <span class="line-clamp-3">{{ __('Edit') }}</span>
                </router-link>
            </template>
        </c-data-table>
        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no tickets available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">{{ __('There are no tickets available.') }}</span>
                <router-link class="text-center text-sm text-highlight"
                             v-bind:to="{ name : 'tickets.create' }"
                             v-if="can('tickets.store')"
                >
                    + {{ __('Add ticket') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import { DateTime } from 'luxon';
    import debounce from 'debounce';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlRadio,
        CControlText,
        CDataTable,
        CIcon,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../repositories/PartnerRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');

    const closeFilters = [
        (e) => {
            if (!e.target.className?.toString().startsWith('dp__')) {
                openFilters.value = false;
            }
        }
    ];

    const filters = ref<object>({});
    const filtersPartner = ref<object>();
    const filtersSpeedial = ref<object>({
        created_at : 'anytime',
        options: [
            { label : __('Today'), value : 'today' },
            { label : __('Yesterday'), value : 'yesterday' },
            { label : __('This week'), value : 'this_week' },
            { label : __('Last week'), value : 'last_week' },
            { label : __('This month'), value : 'this_month' },
            { label : __('Last month'), value : 'last_month' },
            { label : __('This year'), value : 'this_year' },
            { label : __('Last year'), value : 'last_year' },
            { label : __('Anytime'), value : 'anytime' },
        ],
        updated_at : 'anytime',
    });

    const localizationStore = useLocalizationStore();
    const openFilter = ref<string>('partner');
    const openFilters = ref<boolean>(false);
    const pagesCurrent = ref(1);
    const pagesTotal = ref(1);
    const partnerRepository = PartnerRepository.getInstance();

    const s = ref<string>('');
    const search = debounce(async () => {
        if (s.value) {
            filters.value.search = s.value;
        } else {
            delete filters.value.search;
        }

        await getTickets();
    }, 250);

    const sessionStore = useSessionStore();
    const sort = ref<string>('');

    const tableData = ref<object>({
        columnNames: {
            oid: __('ID'),
            title: __('Ticket title'),
            partner: __('Partner'),
            description: __('Ticket description'),
            created_at: __('Created'),
            actions: __('Actions')
        },
        defaultSort: '+oid',
        items: [],
        sortableColumns: ['oid', 'title', 'created_at', 'partner'],
    });

    const ticketRepository = TicketRepository.getInstance();

    function filter() {
        getTickets();

        openFilters.value = false;
    }

    async function getTickets() {
        if (filtersPartner.value) {
            filters.value.partner = filtersPartner.value.id;
        }

        const result = await ticketRepository.index({
            filters: {
                ...filters.value,
                ...{
                    author_id : sessionStore.user.id,
                    is_not_archived : 1
                }
            },
            include: ['site', 'site.partner'],
            page: pagesCurrent.value,
            paginate: 1,
            sort: sort.value,
        });

        pagesCurrent.value = result.current_page;
        pagesTotal.value = result.last_page;

        tableData.value.items = result.data.map((ticket) => {
            return { values : ticket }
        });
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            }
        });
    }

    function resetFilters() {
        filters.value = {};
        filtersPartner.value = null;
        filtersSpeedial.value.created_at = 'anytime';
        filtersSpeedial.value.updated_at = 'anytime';
        s.value = '';

        getTickets();
    }

    async function sortTable(sortBy: string) {
        sort.value = sortBy;

        await getTickets();
    }

    function updateDateFilter(field : string) {
        const dates = (() => {
            switch (filtersSpeedial.value[field]) {
                case 'today':
                    return [
                        DateTime.now(),
                        DateTime.now(),
                    ];
                case 'yesterday':
                    return [
                        DateTime.now().minus({ days: 1}),
                        DateTime.now().minus({ days: 1}),
                    ];
                case 'this_week':
                    return [
                        DateTime.now().startOf('week'),
                        DateTime.now(),
                    ];
                case 'last_week':
                    return [
                        DateTime.now().startOf('week').minus({ days: 7 }),
                        DateTime.now().startOf('week').minus({ days: 1 }),
                    ];
                case 'this_month':
                    return [
                        DateTime.now().startOf('month'),
                        DateTime.now(),
                    ];
                case 'last_month':
                    return [
                        DateTime.now().startOf('month').minus({ month: 1 }),
                        DateTime.now().startOf('month').minus({ days: 1 }),
                    ];
                case 'this_year':
                    return [
                        DateTime.now().startOf('year'),
                        DateTime.now(),
                    ];
                case 'last_year':
                    return [
                        DateTime.now().startOf('year').minus({ year: 1 }),
                        DateTime.now().startOf('year').minus({ days: 1 }),
                    ];
            }
        })();

        if (dates) {
            filters.value[field] = dates;
        } else {
            delete filters.value[field];
        }
    }

    onMounted(async () => {
        await getTickets();
    });
</script>
