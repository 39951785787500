import Repository from './Repository';

export default class ChecklistRepository extends Repository {
    protected basePath = 'checklists';

    protected messages = {
        destroy: this.__('Checklist successfully deleted.'),
        store: this.__('Checklist successfully created.'),
    }

    protected redirects = {}

    public export(params: object = null): Promise {
        console.error('Export method does not exist on ChecklistRepository.');
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on ChecklistRepository.');
    }

    public update(id: string, data: object, params: object = null) {
        console.error('Update method does not exist on ChecklistRepository.');
    }
}