import Repository from './Repository';

export default class TenantRepository extends Repository {
    protected basePath = 'tenants';

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method does not exist on TenantRepository.');
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on TenantRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on TenantRepository.');
    }

    public index(params: object = null): Promise {
        console.error('Index method does not exist on TenantRepository.');
    }

    public store(data: object, params: object = null): Promise {
        console.error('Store method does not exist on TenantRepository.');
    }

    public update(id: string, data: object, params: object = null): Promise {
        console.error('Update method does not exist on TenantRepository.');
    }
}