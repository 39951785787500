import { useLocalizationStore } from '../stores/localization';

export const __ = {
    install: (app, options) => {
        app.config.globalProperties.__ = function (string: string, replacements: object = {}) {
            const localizationStore = useLocalizationStore();
            const strings = localizationStore.strings;

            if (typeof strings !== 'undefined' && strings[string]) {
                return makeReplacements(strings[string], replacements);
            }

            string = string.replace(/role\.|units\./gi, '');

            return makeReplacements(string, replacements);
        }
    },
};

function makeReplacements(string: string, replacements: object = {}) {
    if (JSON.stringify(replacements) === '{}') { // empty
        return string;
    }

    Object.keys(replacements).forEach((key) => {
        string = string.replace(':' + key, replacements[key]);
    });

    return string;
}
