<template>
    <app-bar v-if="localizationStore.strings && !route.meta.hideAppBar" />

    <router-view v-if="localizationStore.strings" />

    <div class="bg-white cursor-progress fixed h-screen left-0 opacity-50 top-0 w-screen z-40"
         v-if="loading"
    ></div>
    <div class="fixed left-0 loading overflow-hidden top-0 w-screen z-50" dusk="loader" v-show="loading">
        <div class="animate-loader relative bg-highlight h-1.5 left-0 opacity-100 top-0 -translate-x-full w-full"></div>
    </div>

    <c-alerts v-bind:alerts="alerts" v-if="alerts"></c-alerts>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import '@vuepic/vue-datepicker/dist/main.css';
    import { CAlerts } from '@teamfurther/cinderblock';

    import { useLocalizationStore } from './stores/localization';
    import { useRequestStore } from './stores/request';
    import { useSessionStore } from './stores/session';

    import AppBar from './components/AppBar.vue';

    const alerts = ref<object[]>([]);
    const can = inject('can');
    const loading = ref<Boolean>(false);
    const localizationStore = useLocalizationStore();
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
        loading.value = true;

        await localizationStore.getStrings();

        loading.value = false;
    });

    router.beforeEach((to, from) => {
        const sessionStore = useSessionStore();

        sessionStore.getUserFromLocalStorage();

        if (to.path === '/') {
            if (sessionStore.user) {
                router.push({ name : 'tickets' })
            } else {
                router.push({ name : 'login' })
            }
        }

        if (!sessionStore.user && to.meta.requiresAuth) {
            router.push({ name : 'login' })
        }

        if (to.meta.permission && !can(to.meta.permission)) {
            router.push({name: 'errors.404'});
        }
    });

    watch(() => requestStore.loading, () => {
        loading.value = requestStore.loading > 0;
    });

    watch(() => requestStore.status, () => {
        if (requestStore.status) {
            alerts.value.push({
                message: requestStore.message,
                title: requestStore.title,
                type: requestStore.status === 200 ? 'success' : 'error'
            });
        }
    });
</script>
