<template>
    <div class="container px-1 py-10">
        <form class="bg-white border border-slate-200 max-w-[600px] mx-auto p-5 rounded shadow-sm"
              v-on:submit.prevent="submit"
        >
            <img class="h-6 mb-5 md:mb-10" src="/img/logo.svg" v-bind:alt="app.name" />

            <c-control-text class="mb-10" name="password" placeholder="**********" required type="password"
                            v-bind:invalid="!!requestStore.errors?.password"
                            v-bind:label="__('New password')"
                            v-model="password"
            />

            <c-control-text class="mb-10" name="password_confirmation" placeholder="**********" required type="password"
                            v-bind:invalid="!!requestStore.errors?.password_confirmation"
                            v-bind:label="__('Confirm password')"
                            v-model="passwordConfirmation"
            />

            <input name="email" type="hidden" v-model="email" />
            <input name="token" type="hidden" v-model="token" />

            <c-button class="bg-highlight text-white" submit>{{ __('Reset password') }}</c-button>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlText,
    } from '@teamfurther/cinderblock';

    import { useRequestStore }  from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';

    const password = ref<string>('');
    const passwordConfirmation = ref<string>('');
    const requestStore = useRequestStore();
    const route = useRoute();
    const sessionStore = useSessionStore();
    const email = route.query.email;
    const token = route.params.token;

    async function submit() {
        await sessionStore.resetPassword(email, password.value, passwordConfirmation.value, token);
    }

    onMounted(() => {
        if (sessionStore.user) {
            requestStore.redirect({ name: 'errors.403' });
        }
    });
</script>
