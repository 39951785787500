<template>
    <c-modal class="max-w-[900px]" v-bind:open="true" v-if="ticket" v-on:closeModal="closeDestination">
        <template v-slot:header>
            <div class="flex items-center justify-between w-full">
                <span class="text-slate-500">#{{ ticket.oid }}</span>
                <span class="pr-10 text-slate-500 text-xs">
                    {{
                        DateTime.fromISO(
                            ticket.created_at,
                            { locale : localizationStore.locale }
                        ).toRelative()
                    }}
                </span>
            </div>
        </template>
        <template v-slot:body>
            <div class="flex flex-col">
                <c-control-text class="mb-5" id="ticket.title" name="ticket.title" required
                                v-bind:invalid="!!requestStore.errors?.title"
                                v-bind:label="__('Ticket title')"
                                v-model="ticket.title"
                />

                <c-control-autocomplete class="mb-5" label-field="name" name="ticket.partner" required
                                        v-bind:invalid="!!requestStore.errors?.partner"
                                        v-bind:label="__('Partner')"
                                        v-bind:placeholder="__('Search by partner name or tax ID')"
                                        v-bind:source-get="partnerGet"
                                        v-bind:source-search="partnerSearch"
                                        v-model="partner"
                />

                <c-control-select class="mb-5" name="ticket.site" required
                                  v-bind:invalid="!!requestStore.errors?.partner_site_id"
                                  v-bind:label="__('Site')"
                                  v-bind:options="sites"
                                  v-model="ticket.partner_site_id"
                />

                <c-control-text id="ticket.description" name="ticket.description" rows="10" type="textarea"
                                v-bind:invalid="!!requestStore.errors?.description"
                                v-bind:label="__('Ticket description')"
                                v-model="ticket.description"
                />
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlSelect,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import PartnerRepository from '../../repositories/PartnerRepository';
    import TicketRepository from '../../repositories/TicketRepository';

    const localizationStore = useLocalizationStore();
    const partner = ref<Object>({});
    const partnerRepository = PartnerRepository.getInstance();
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const sites = ref<Object[]>();
    const ticket = ref<object>();
    const ticketId = route.params.ticket;
    const ticketRepository = TicketRepository.getInstance();

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'tickets/me' });
        } else {
            router.go(-1);
        }
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            },
            include: ['sites']
        });
    }

    async function submit() {
        ticket.value = await ticketRepository.update(ticketId, ticket.value, {
            include: ['assignee', 'site', 'site.partner', 'site.partner.sites']
        });
    }

    onMounted(async () => {
        ticket.value = await ticketRepository.show(ticketId, {
            include: ['assignee', 'site', 'site.partner', 'site.partner.sites']
        });

        partner.value = ticket.value.site.partner;
        sites.value = partner.value.sites.map((site) => {
            return {
                label : site['name'],
                value : site['id'],
            }
        });
    });

    watch(() => partner.value, () => {
        if (partner.value.id !== ticket.value.site.partner.id) {
            ticket.value.partner_site_id = null;
            ticket.value.site = null;
        }

        sites.value = partner.value.sites.map((site) => {
            return {
                label : site['name'],
                value : site['id'],
            }
        });
    });
</script>
