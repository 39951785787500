<template>
    <div class="container px-1 py-5">
        <h1 class="lg:text-3xl mb-5 md:mb-10 mr-10 text-2xl">{{ __('Change plan') }}</h1>

        <div class="flex flex-col">
            <div class="border inline-flex mb-10 mx-auto relative rounded-full w-[250px]">
                <span class="cursor-pointer duration-500 py-4 relative text-center transition-all w-1/2 z-20"
                      v-bind:class="{ 'text-white' : selectedPrice === 'monthly' }"
                      v-on:click="selectedPrice = 'monthly'; previewInvoice();"
                >
                    {{ __('Monthly') }}
                </span>
                <span class="cursor-pointer duration-500 py-4 relative text-center transition-all w-1/2 z-20"
                      v-bind:class="{ 'text-white' : selectedPrice === 'yearly' }"
                      v-on:click="selectedPrice = 'yearly'; previewInvoice();"
                >
                    {{ __('Yearly') }}
                </span>
                <span class="absolute bg-highlight duration-500 h-full rounded-full top-0 transition-all transl w-1/2 z-10"
                      v-bind:class="{ 'translate-x-full' : selectedPrice === 'yearly' }"
                />
                <div class="absolute flex items-center -right-1 -top-2 translate-x-full">
                    <img class="h-4 mr-2" src="/img/subscriptions-save.svg" v-bind:alt="__('Save 10%')" />
                    <span class="font-bold -mt-1 text-highlight text-lg">{{ __('Save 10%') }}</span>
                </div>
            </div>

            <div class="flex flex-col md:flex-row md:items-start" v-if="Object.keys(products).length > 0">
                <div class="flex flex-col md:mr-4 md:w-7/12">
                    <div class="bg-white border border-slate-200 cursor-pointer flex items-center p-5 rounded-2xl shadow-lg text-center"
                         v-bind:class="{ '!border-highlight' : selectedProduct === 'essential' }"
                         v-on:click="selectedProduct = 'essential'; previewInvoice();"
                    >
                        <div class="border-r border-slate-200 mr-5 pr-5 w-1/2">
                            <h4 class="font-bold text-lg">Essential</h4>
                        </div>
                        <div class="w-1/2">
                        <span class="text-slate-500 text-sm">
                            <span class="text-lg">
                                {{
                                    currencyStore.format(
                                        products.essential.prices[selectedPrice].amount,
                                        products.essential.prices[selectedPrice].currency
                                    )
                                }}
                            </span>
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                            / {{ __('seat') }}
                        </span>
                        </div>
                    </div>
                    <div class="bg-white border border-slate-200 cursor-pointer flex items-center mt-5 p-5 rounded-2xl shadow-lg text-center"
                         v-bind:class="{ '!border-highlight' : selectedProduct === 'extra' }"
                         v-on:click="selectedProduct = 'extra';  previewInvoice();"
                    >
                        <div class="border-r border-slate-200 mr-5 pr-5 w-1/2">
                            <h4 class="font-bold text-lg">Extra</h4>
                        </div>
                        <div class="w-1/2">
                        <span class="text-slate-500 text-sm">
                            <span class="text-lg">
                                {{
                                    currencyStore.format(
                                        products.extra.prices[selectedPrice].amount,
                                        products.extra.prices[selectedPrice].currency
                                    )
                                }}
                            </span>
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                            / {{ __('seat') }}
                        </span>
                        </div>
                    </div>
                </div>

                <div class="bg-white border border-slate-200 md:w-5/12 p-2 rounded" v-if="invoicePreview">
                    <h2 class="mb-10">{{ __('Your summary') }}</h2>

                    <span class="block mb-5" v-html="__('Your next billing cycle is on :date and you will be charged for :amount.', {
                        date: '<strong>' + DateTime.fromSeconds(invoicePreview['date']).toFormat(localizationStore.dateFormat) + '</strong>',
                        amount: '<strong>' + currencyStore.format(invoicePreview['amount'], subscription.currency) + '</strong>'
                    })" />

                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Seats') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ seats }}</span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Price per seat') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">
                            {{
                                currencyStore.format(
                                    products[selectedProduct]?.prices[selectedPrice].amount,
                                    products[selectedProduct]?.prices[selectedPrice].currency
                                )
                            }}
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                        </span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Subscription price') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">
                            {{
                                currencyStore.format(
                                    subscriptionAmount,
                                    products[selectedProduct]?.prices[selectedPrice].currency
                                )
                            }}
                        </span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Balance') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">
                            {{ currencyStore.format(invoicePreview['proration'], subscription.currency) }}
                        </span>
                    </span>
                    <span class="flex mt-5 text-sm">
                        <span class="font-semibold shrink-0 text-slate-500">{{ __('Total') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="font-semibold shrink-0">
                            {{
                                currencyStore.format(
                                    invoicePreview['amount'],
                                    products[selectedProduct]?.prices[selectedPrice].currency
                                )
                            }}
                        </span>
                    </span>

                    <c-button class="bg-success mt-10 text-white w-full"
                              v-bind:disabled="!invoicePreview || loading"
                              v-on:click="changePlan"
                    >
                        {{ __('Change plan') }}
                    </c-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, onMounted, ref } from 'vue';
    import { DateTime } from 'luxon';
    import {
        CButton,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import { useCurrencyStore } from '../../stores/currency';
    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';
    import { useSubscriptionsStore }  from '../../stores/subscriptions';

    const __ = inject('__');
    const currencyStore = useCurrencyStore();
    const employeeRepository = EmployeeRepository.getInstance();
    const invoicePreview = ref<object>();
    const loading = ref<boolean>(false);
    const localizationStore = useLocalizationStore();
    const products = ref<object>({});

    const requestStore = useRequestStore();
    const seats = ref<number>(1);
    const sessionStore = useSessionStore();

    const subscriptionAmount = computed(() => {
        return seats.value * products.value[selectedProduct.value]?.prices[selectedPrice.value].amount;
    });

    const subscriptionsStore = useSubscriptionsStore();
    const tenant = sessionStore.user.tenant;
    const subscription = tenant.subscription_plan;
    const selectedPrice = ref<string>(tenant.subscription_plan.price);
    const selectedProduct = ref<string>(tenant.subscription_plan.product);

    async function changePlan() {
        loading.value = true;

        await requestStore.post('subscriptions/change-plan', {
            price: selectedPrice.value,
            product: selectedProduct.value,
        }).then(async () => {
            await sessionStore.refreshUser();

            requestStore.redirect({ name: 'subscriptions.billing' }, {
                message: __('Subscription status successfully updated.'),
                status: 200,
                title: __('Success'),
            });
        }).catch(async () => {
            loading.value = false;
        });
    }

    async function previewInvoice() {
        if (tenant.is_on_generic_trial) {
            invoicePreview.value = {
                amount : subscriptionAmount.value,
                currency : subscription.currency,
                date : DateTime.fromISO(tenant.trial_ends_at).toSeconds(),
                proration : 0,
            }

            return;
        }

        loading.value = true;

        await requestStore.post('subscriptions/preview-invoice', {
            price: selectedPrice.value,
            product: selectedProduct.value,
        }).then((response) => {
            invoicePreview.value = response.data;
        });

        loading.value = false;
    }

    onMounted(async () => {
        products.value = await subscriptionsStore.getProducts();

        seats.value = await employeeRepository.index().then((employees) => {
            return employees.length;
        });

        previewInvoice();
    });
</script>