import Repository from './Repository';

export default class PartnerSiteContactRepository extends Repository {
    protected basePath = 'partners/sites/contacts';

    protected messages = {
        destroy : this.__('Contact person successfully deleted.'),
        store : this.__('Contact person successfully created.'),
        update : this.__('Contact person successfully updated.'),
    }

    protected redirects = {
        store: { name : 'partners.sites.edit' }
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on PartnerSiteContactRepository.');
    }

    public import(params: object = null): Promise {
        console.error('Import method does not exist on PartnerSiteContactRepository.');
    }
}