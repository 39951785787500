<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-start justify-between mb-10 md:flex-row md:items-center">
            <div class="flex flex-col mb-5 md:mb-0 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Capacity reports') }}</h1>

                <div class="flex items-center justify-between">
                    <div class="relative">
                        <a class="cursor-pointer duration-500 flex items-center p-1 rounded text-sm transition-all"
                           v-bind:class="{ 'bg-highlight text-white' : Object.keys(filters).length }"
                           v-on:click="openFilters = true"
                        >
                            <c-icon class="h-5 mr-2 stroke-slate-800 w-5" icon="filter"
                                    v-bind:class="{ '!stroke-white' : Object.keys(filters).length }"
                            />
                            {{ __('Filter results') }}
                            <span class="bg-white flex font-bold h-4 items-center justify-center ml-1 rounded-full text-center text-highlight text-xs w-4"
                                  v-if="Object.keys(filters).length"
                            >
                                {{ Object.keys(filters).length }}
                            </span>
                        </a>
                        <div class="absolute bg-white border border-slate-200 hidden md:w-[500px] mt-1 rounded shadow-lg w-[calc(100vw-0.5rem)] z-30"
                             v-bind:class="{ '!block' : openFilters }"
                             v-on-click-outside="closeFilters"
                        >
                            <div class="flex">
                                <nav class="bg-slate-50 border-r border-slate-200 flex flex-col shrink-0 text-sm">
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'date' }"
                                       v-on:click="openFilter = 'date'"
                                    >
                                        {{ __('Date') }}
                                    </a>
                                    <a class="cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'assignee' }"
                                       v-on:click="openFilter = 'assignee'"
                                    >
                                        {{ __('Assignee') }}
                                    </a>
                                </nav>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'date' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.date.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.date"
                                                         v-on:change="updateDateFilter"
                                        />
                                    </div>
                                    <c-control-date name="filters.date"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.date"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'assignee' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.assignee"
                                                            avatar-field="avatar"
                                                            label-field="name"
                                                            v-bind:placeholder="__('Search by employee name')"
                                                            v-bind:source-get="employeeGet"
                                                            v-bind:source-search="employeeSearch"
                                                            v-model="filtersEmployee"
                                    />
                                </div>
                            </div>
                            <div class="border-t border-slate-200 flex justify-end p-2">
                                <c-button class="mr-2 p-1.5 text-error" v-on:click="resetFilters">
                                    {{ __('Reset filters') }}
                                </c-button>
                                <c-button class="bg-highlight p-1.5 shrink-0 text-white" v-on:click="filter">
                                    {{ __('Apply filters') }}
                                </c-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-1">
        <div class="relative" v-if="time">
            <div class="md:ml-[254px] md:w-[calc(100%-254px)] ml-[152px] overflow-x-scroll overflow-y-visible w-[calc(100%-152px)]">
                <div class="mb-20 w-[1488px]"
                     v-for="date in Interval.fromDateTimes(dates[0], dates[1]).splitBy({ days : 1})"
                >
                    <div class="mb-0.5 relative w-full z-20">
                        <div class="bg-slate-200 font-bold mx-px px-1 py-2 text-center text-xs uppercase">
                            {{
                                DateTime.fromISO(
                                    date.start,
                                    { locale : localizationStore.locale }
                                ).toFormat('MMM d., EEEE')
                            }}
                        </div>
                    </div>
                    <div class="flex mb-0.5 w-full">
                        <div class="absolute bg-slate-200 font-bold md:-ml-[254px] -ml-[152px] mx-px px-1 py-2 text-xs w-[150px]">
                            Munkavállaló
                        </div>
                        <div class="absolute bg-slate-200 font-bold hidden md:block -ml-[102px] mx-px px-1 py-2 text-xs w-[100px]">
                            Munkaidó
                        </div>
                        <div class="bg-slate-400 font-bold mx-px px-1 py-2 text-center text-white text-xs w-[60px]"
                             v-for="hour in 24"
                             v-if="time[DateTime.fromISO(date.start).toFormat('yyyy-MM-dd')]"
                        >
                            {{ (hour - 1).toString().padStart(2, '0') + ':00' }}
                        </div>
                        <div class="mx-px p-1 text-center text-sm w-[1440px]" v-else>
                            {{ __('There are no jobs scheduled for this day.') }}
                        </div>
                    </div>
                    <div class="bg-capacity-table"
                         v-bind:class="{ 'before:absolute before:bg-slate-200 before:block before:content-[\'\'] before:h-[2px] before:left-0 before:md:w-[254px] before:-mt-1 before:-translate-y-[2px] before:w-[152px] border-t-[2px] border-slate-200 pt-1' : i > 0 }"
                         v-for="(assignee, assigneeId, i) in time[DateTime.fromISO(date.start).toFormat('yyyy-MM-dd')]"
                         v-if="time[DateTime.fromISO(date.start).toFormat('yyyy-MM-dd')]"
                    >
                        <div class="flex pb-1 w-full"
                             v-bind:class="{ 'py-1' : j > 0 }"
                             v-for="(ticket, ticketId, j) in assignee.tickets"
                        >
                            <div class="absolute font-medium md:-ml-[254px] -ml-[152px] mx-px p-1 text-xs w-[150px]"
                                 v-if="j === 0"
                            >
                                {{ assignee.assignee.name }} <em class="md:hidden">({{ __(':hours hours', { hours: assignee.time }) }})</em>
                            </div>
                            <div class="absolute font-medium hidden md:block -ml-[102px] mx-px p-1 text-xs w-[100px]"
                                 v-if="j === 0"
                            >
                                {{ __(':hours hours', { hours: assignee.time }) }}
                            </div>
                            <div class="flex mx-px overflow-hidden w-[1488px]">
                                <div class="p-0" v-bind:style="'width: calc(62px*' + ticket.schedule_before/60 + ')'">&nbsp;</div>
                                <router-link class="bg-green-100 block overflow-hidden px-3 py-1 relative text-ellipsis text-xs whitespace-nowrap"
                                             v-bind:class="{
                                                    '!bg-indigo-100' : !ticket.worksheet || !ticket.worksheet.signed_at,
                                                    'rounded-l-full' : DateTime.fromISO(date.start).toFormat('yyyy-MM-dd') === ticket.schedule_start_date,
                                                    'rounded-r-full' : DateTime.fromISO(date.start).toFormat('yyyy-MM-dd') === ticket.schedule_end_date || (DateTime.fromISO(date.start).plus({ days: 1 }).toFormat('yyyy-MM-dd') === ticket.schedule_end_date && ticket.schedule_end_time === '00:00:00')
                                             }"
                                             v-bind:style="'width: calc(62px*' + ticket.schedule_length/60 + ')'"
                                             v-bind:title="ticket.site.name + ' (' + ticket.site.partner.name + ')'"
                                             v-bind:to="{
                                                name : ticket.worksheet ? 'tickets.show' : 'tickets.edit',
                                                params: { ticket : ticket.id }
                                             }"
                                >
                                    <span class="absolute bg-rose-200 block h-full left-0 top-0 z-10"
                                          v-bind:style="'transform: translateX(calc(62px*' + overlap.start/60 + ')); width: calc(62px*' + overlap.length/60 + ')'"
                                          v-for="overlap in ticket.schedule_overlaps"
                                    />
                                    <span class="relative z-20">
                                        <span class="text-2xs">
                                            {{ DateTime.fromISO(ticket.schedule_start_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                                        </span>
                                        <span class="text-2xs" v-if="ticket.schedule_end_time">
                                            - {{ DateTime.fromISO(ticket.schedule_end_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                                        </span><br />
                                        <strong class="mr-1">{{ ticket.site.name }}</strong>{{ ticket.site.partner.name }}
                                    </span>
                                </router-link>
                                <div class="p-0" v-bind:style="'width: calc(62px*' + ticket.schedule_after/60 + ')'">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no results available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">{{ __('There are no results available.') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import { DateTime, Interval } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlRadio,
        CIcon,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';
    import TicketTimeRepository from '../../repositories/TicketTimeRepository';

    const __ = inject('__');

    const closeFilters = [
        (e) => {
            if (!e.target.className?.toString().startsWith('dp__')) {
                openFilters.value = false;
            }
        }
    ];

    const dates = ref([
        DateTime.now().startOf('week'),
        DateTime.now().endOf('week'),
    ]);
    const employeeRepository = EmployeeRepository.getInstance();

    const filters = ref<object>({
        date : [
            DateTime.now().startOf('week'),
            DateTime.now().endOf('week'),
        ],
    });
    const filtersEmployee = ref<object>();
    const filtersSpeedial = ref<object>({
        date : 'this_week',
        options: [
            { label : __('Today'), value : 'today' },
            { label : __('Tomorrow'), value : 'tomorrow' },
            { label : __('This week'), value : 'this_week' },
            { label : __('Next week'), value : 'next_week' },
        ],
    });
    const filtersTicket = ref<object>();
    const localizationStore = useLocalizationStore();
    const openFilter = ref<string>('date');
    const openFilters = ref<boolean>(false);
    const sessionStore = useSessionStore();
    const ticketTimeRepository = TicketTimeRepository.getInstance();
    const time = ref<object>({});

    async function employeeGet(employeeId) {
        if (!employeeId.value) {
            return null;
        }

        return await employeeRepository.show(employeeId.value.id);
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                search: val
            }
        });
    }

    function filter() {
        dates.value = filters.value.date;
        getResults();

        openFilters.value = false;
    }

    async function getResults() {
        if (filtersEmployee.value) {
            filters.value.assignee_id = filtersEmployee.value.id;
        } else {
            delete filters.value.assignee_id;
        }

        if (filtersTicket.value) {
            filters.value.ticket_id = filtersTicket.value.id;
        } else {
            delete filters.value.ticket_id;
        }

        time.value = await ticketTimeRepository.index({
            filters: filters.value,
        });
    }

    function resetFilters() {
        filters.value = {
            date : [
                DateTime.now().startOf('week'),
                DateTime.now().endOf('week'),
            ],
        };
        filtersEmployee.value = null;
        filtersTicket.value = null;
        filtersSpeedial.value.date = 'this_week';

        dates.value = filters.value.date;

        openFilters.value = false;

        getResults();
    }

    function updateDateFilter() {
        const dates = (() => {
            switch (filtersSpeedial.value.date) {
                case 'today':
                    return [
                        DateTime.now(),
                        DateTime.now(),
                    ];
                case 'tomorrow':
                    return [
                        DateTime.now().plus({ days: 1}),
                        DateTime.now().plus({ days: 1}),
                    ];
                case 'this_week':
                    return [
                        DateTime.now().startOf('week'),
                        DateTime.now().endOf('week'),
                    ];
                case 'next_week':
                    return [
                        DateTime.now().endOf('week').plus({ days: 1 }),
                        DateTime.now().endOf('week').plus({ days: 7 }),
                    ];
            }
        })();

        if (dates) {
            filters.value.date = dates;
        } else {
            delete filters.value.date;
        }
    }

    onMounted(async () => {
        await getResults();
    });
</script>
