<template>
    <div class="container px-1 py-10">
        <form class="bg-white border border-slate-200 max-w-[600px] mx-auto p-5 rounded shadow-sm"
              v-on:submit.prevent="submit"
        >
            <div class="bg-slate-100 flex h-28 items-center justify-center mb-10 mx-auto rounded-lg w-28">
                <c-icon class="h-16 stroke-highlight w-16" icon="mail" />
            </div>

            <h1 class="font-semibold mb-2 text-center text-slate-500 text-xl">
                {{ __('Check your inbox.') }}
            </h1>
            <p class="mb-10 text-center text-slate-400 text-sm">
                {{ __('We have sent an email containing your password reset code.') }}
            </p>

            <input class="bg-slate-50 border border-slate-200 h-16 mb-5 rounded-lg text-center text-3xl tracking-widest w-full" autocomplete="off" name="code" placeholder="______" type="text"
                   v-bind:class="{ 'border-error placeholder:text-red-300 text-error' : !!requestStore.errors?.code }"
                   v-on:keydown="validateKey"
                   v-on:input="validateInput"
                   v-model="code"
            />

            <c-button class="bg-highlight mb-10 mx-auto table text-white" submit>{{ __('Reset password') }}</c-button>

            <p class="text-center text-sm">
                {{ __('Didn\'t receive an email? Check your spam folder or try a different email address.') }}
            </p>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CIcon,
    } from '@teamfurther/cinderblock';

    import { useRequestStore }  from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const code = ref<string>('');
    const requestStore = useRequestStore();
    const route = useRoute();
    const email = route.query.email;
    const sessionStore = useSessionStore();

    function validateKey(e) {
        if (
            ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.code) ||
            !isNaN(Number(e.key)) ||
            e.metaKey === true
        ) {
            return true;
        }

        e.preventDefault();
    }

    async function submit() {
        await sessionStore.setResetToken(code.value, email);
    }

    function validateInput(e) {
        e.target.value = e.target.value.replace(/[^0-9]*/g, '').substring(0, 6);
    }

    onMounted(() => {
        if (sessionStore.user) {
            requestStore.redirect({ name: 'errors.403' });
        }
    });
</script>
