import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';

import { useRequestStore } from './request';

export const useSessionStore = defineStore('session', () => {
    const home = 'tickets';

    const requestStore = useRequestStore();
    const router = useRouter();
    const token = ref<string>('');
    const user = ref<object>();

    function getUserFromLocalStorage() {
        if (window.localStorage.getItem('token')) {
            token.value = window.localStorage.getItem('token');
        }

        if (window.localStorage.getItem('user')) {
            user.value = JSON.parse(window.localStorage.getItem('user'));
        }
    }

    async function logUserIn(email, password) {
        await requestStore.post('login', {
            email: email,
            password: password,
        }).then((response: any) => {
            const data = response.data;

            token.value = data.token;
            user.value = data.user;

            window.localStorage.setItem('token', data.token);
            window.localStorage.setItem('user', JSON.stringify(data.user));

            router.push({ name : 'tickets' });
        });
    }

    async function logUserInByToken(loginToken) {
        await requestStore.post('login/' + loginToken, {}).then((response: any) => {
            const data = response.data;

            token.value = data.token;
            user.value = data.user;

            window.localStorage.setItem('token', data.token);
            window.localStorage.setItem('user', JSON.stringify(data.user));

            router.push({ name : 'tickets' });
        });
    }

    function logUserOut() {
        token.value = '';
        user.value = null;

        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');

        router.push({ name : 'login' });
    }

    async function refreshUser() {
        if (!user.value?.id) {
            return {};
        }

        await requestStore.get('users/' + user.value.id + '?include=tenant', {
            headers: {
                Authorization: `Bearer ${token.value}`
            }
        }).then((response: any) => {
            user.value = response.data;
        });

        window.localStorage.setItem('user', JSON.stringify(user.value));
    }

    async function registerUser(accepted, name, email, password, company, utm_campaign, utm_medium, utm_source) {
        await requestStore.post('register', {
            accepted: accepted,
            company: company,
            email: email,
            name: name,
            password: password,
            utm_campaign: utm_campaign,
            utm_medium: utm_medium,
            utm_source: utm_source,
        }).then((response: any) => {
            const data = response.data;

            token.value = data.token;
            user.value = data.user;

            window.localStorage.setItem('token', data.token);
            window.localStorage.setItem('user', JSON.stringify(data.user));

            router.push({ name : 'tickets' });
        });
    }

    async function resetPassword(email, password, passwordConfirmation, token) {
        await requestStore.post('password/reset', {
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            token: token,
        }).then((response: any) => {
            requestStore.redirect({ name : 'login' }, {});
        });
    }

    async function sendPasswordReset(email) {
        await requestStore.post('password/email', {
            email: email,
        }).then((response: any) => {
            requestStore.redirect(
                { name : 'password-email', query : { email : email } },
                {}
            );
        }).catch(error => Promise.reject(error));
    }

    async function setResetToken(code, email) {
        await requestStore.post('password/token', {
            code: code,
            email: email,
        }).then((response: any) => {
            const code = JSON.parse(response.data.token);

            if (code.token) {
                requestStore.redirect(
                    {
                        name : 'reset-password',
                        query : { email : code.email },
                        params: { token : code.token }
                    },
                    {}
                );
            }
        }).catch(error => Promise.reject(error));
    }

    onMounted(async () => {
        if (!user.value) {
            getUserFromLocalStorage();
        }

        await refreshUser();
    });

    return {
        getUserFromLocalStorage,
        home,
        logUserIn,
        logUserInByToken,
        logUserOut,
        registerUser,
        refreshUser,
        resetPassword,
        sendPasswordReset,
        setResetToken,
        token,
        user
    }
});
