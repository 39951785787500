<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ employee.name }}</h1>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 lg:min-w-[200px] mr-2 text-slate-500" dusk="cancel-button" router
                          v-bind:to="{ name : 'employees' }"
                >
                    {{ __('Cancel') }}
                </c-button>

                <c-button class="bg-success lg:min-w-[200px] text-white"
                          dusk="save-button"
                          v-if="can('users.update')"
                          v-on:click="submit"
                >
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-20"
                      v-else-if="can('users.update')"
                      v-on:click="submit"
            >
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="grid lg:grid-cols-3 gap-10">
            <div class="col-span-2">
                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Employee details') }}</h2>
                    <c-control-text class="mb-10" name="employee.name" required
                                    v-bind:invalid="!!requestStore.errors?.name"
                                    v-bind:label="__('Name')"
                                    v-bind:placeholder="__('placeholders.person_name')"
                                    v-model="employee.name"
                    />
                    <c-control-text class="mb-10" name="employee.email" required
                                    v-bind:invalid="!!requestStore.errors?.email"
                                    v-bind:label="__('Email')"
                                    v-bind:placeholder="__('placeholders.email')"
                                    v-model="employee.email"
                    />
                    <c-control-select name="employee.role" required
                                      v-bind:invalid="!!requestStore.errors?.role"
                                      v-bind:label="__('Role')"
                                      v-bind:options="roles"
                                      v-model="employee.role"
                    />
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="fields.length > 0 && employee.fieldValues">
                    <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

                    <template v-for="field in fields">
                        <div class="mt-5">
                            <div class="bg-indigo-50 mb-1 p-2 rounded"
                                 v-for="repetition in computeFieldRepetitions(field)"
                                 v-if="field.fields && field.fields.length > 0"
                            >
                                <div class="flex items-center justify-between mb-4">
                                    <strong class="text-sm">{{ field.label }}</strong>
                                    <a class="cursor-pointer"
                                       v-if="repetition > 1"
                                       v-on:click="deleteFieldRepetition(field, repetition)"
                                    >
                                        <c-icon class="h-6 stroke-error w-6" icon="trash-2" />
                                    </a>
                                </div>
                                <div class="mb-5"
                                     v-for="subfield in field.fields"
                                >
                                    <custom-field v-bind:field="subfield"
                                                  v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                                  v-model="employee.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                                    />
                                </div>
                                <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                                   v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                                   v-on:click="addFieldRepetition(field)"
                                >
                                    <c-icon class="h-6 stroke-white w-6" icon="plus" />
                                </a>
                            </div>
                            <custom-field v-bind:field="field"
                                          v-bind:id="field.key"
                                          v-else
                                          v-model="employee.fieldValues[field.key]"
                            />
                        </div>
                    </template>
                </div>
            </div>

            <div>
                <h2 class="font-medium mb-5 text-lg">{{ __('Attached documents') }}</h2>
                <div class="grid grid-cols-2 gap-4">
                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                       v-bind:href="file.url"
                       v-for="(file, key) in employee.files"
                    >
                        <img class="max-h-32 rounded"
                             v-bind:alt="file.name"
                             v-bind:src="file.url"
                             v-if="isImage(file.name)"
                        />
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                v-else
                        />

                        <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file.name }}
                            </span>

                        <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error" dusk="delete-file-button"
                              v-on:click.prevent="deleteFile(key)"
                        >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                    </a>

                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                       v-bind:href="newFiles[key]"
                       v-for="(file, key) in newFilenames"
                    >
                        <img class="max-h-32 rounded"
                             v-bind:alt="file"
                             v-bind:src="newFiles[key]"
                             v-if="isImage(file)"
                        />
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                v-else
                        />

                        <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file }}
                            </span>

                        <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error"
                              v-on:click.prevent="deleteFile(key, true)"
                        >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                    </a>

                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex group hover:border-slate-500 items-center justify-center p-2 rounded transition-all"
                       dusk="upload-button"
                       v-if="can('users.update')"
                       v-on:click="upload"
                    >
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                    </a>
                </div>

                <c-control-file class="h-0 opacity-0 pointer-events-none w-0" multiple name="employee.files" v-bind:filenames="newFilenames" v-model="newFiles" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlFile,
        CControlSelect,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';

    import { useRequestStore } from '../../stores/request';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import RoleRepository from '../../repositories/RoleRepository';

    import CustomField from '../../components/CustomField.vue';

    const __ = inject('__');
    const employee = ref<object>({});
    const employeeRepository = EmployeeRepository.getInstance();
    const fields = ref<Object[]>([]);
    const floatSave = ref<boolean>(false);
    const newFilenames = ref<String[]>([]);
    const newFiles = ref<Object[]>([]);
    const requestStore = useRequestStore();
    const roleRepository = RoleRepository.getInstance();
    const roles = ref<Object[]>([]);
    const route = useRoute();
    const employeeId = route.params.employee;

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            employee.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                user_id: employee.value.id,
            });
        });

        computeEmployeeFieldValues();
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        employee.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computeEmployeeFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = employee.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        employee.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            user_id: employee.value.id,
                        });
                    }
                });
            } else {
                const found = employee.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    employee.value.fields.push({
                        key: field.key,
                        value: null,
                        user_id: employee.value.id,
                    });
                }
            }
        });

        employee.value.fieldValues = employee.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            employee.value.fields = employee.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = employee.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                employee.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computeEmployeeFieldValues();
    }

    function deleteFile(key: number, isNew: boolean = false) {
        if (!isNew) {
            employee.value.files.splice(key, 1);
        } else {
            newFilenames.value.splice(key, 1);
            newFiles.value.splice(key, 1);
        }
    }

    async function submit() {
        employee.value.newFilenames = newFilenames.value;
        employee.value.newFiles = newFiles.value;
        employee.value = await employeeRepository.update(employeeId, employee.value, {
            include: [
                'fields',
                'files',
                'roles',
            ]
        });
        employee.value.role = employee.value.roles[0].id;

        computeEmployeeFieldValues();

        newFilenames.value = [];
        newFiles.value = [];
    }

    function upload() {
        document.getElementById('employee.files').click();
    }

    onMounted(async () => {
        const rolesTemp = await roleRepository.index();
        roles.value = rolesTemp.map((role) => {
            return {
                label : __('role.' + role['name']),
                value : role['id'],
            }
        });

        await requestStore.get('users/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        employee.value = await employeeRepository.show(employeeId, {
            include: [
                'fields',
                'files',
                'roles',
            ]
        });

        computeEmployeeFieldValues();

        if (employee.value.roles[0]) {
            employee.value.role = employee.value.roles[0].id;
        }

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });

    watch(() => employee.value, () => {
        Object.keys(employee.value.fieldValues).forEach(key => {
            let field = employee.value.fields.find(f => f.key === key);

            if (field) {
                field.value = employee.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
