<template>
    <c-modal class="max-w-[600px]"
             v-bind:open="open"
             v-bind:title="__('Edit time logs')"
             v-on:closeModal="closeDestination"
    >
        <template v-slot:body>
            <div class="flex flex-col" v-if="worksheet">
                <div class="mb-5" v-for="assignee in timeLogsGroupedByAssignee">
                    <div class="mb-2">
                        <img class="h-6 inline mr-2 -mt-1 rounded w-6"
                             v-bind:src="assignee.assignee.avatar"
                             v-bind:alt="assignee.assignee.name"
                        />
                        <span class="text-sm">{{ assignee.assignee.name }}</span>
                    </div>
                    <div class="border-b border-dotted border-slate-200 flex items-center justify-between mb-1 pb-1"
                         v-for="log in assignee.items"
                    >
                        <span class="italic mb-px py-2 text-slate-400 text-xs">
                            <span class="relative"
                                  v-on:mouseover="tooltipTime = log.id + '_started'"
                                  v-on:mouseout="tooltipTime = null"
                            >
                                {{
                                    DateTime.fromISO(
                                        log.started_at,
                                        { locale : localizationStore.locale }
                                    ).toLocaleString(DateTime.TIME_24_SIMPLE)
                                }}
                                <c-tooltip class="ml-1" position="right"
                                           v-bind:active="tooltipTime === log.id + '_started'"
                                >
                                    {{
                                        DateTime.fromISO(
                                            log.started_at,
                                            { locale : localizationStore.locale }
                                        ).toFormat(localizationStore.dateFormat)
                                    }}
                                </c-tooltip>
                            </span>
                            -
                            <span class="relative"
                                  v-on:mouseover="tooltipTime = log.id + '_stopped'"
                                  v-on:mouseout="tooltipTime = null"
                            >
                                {{
                                    DateTime.fromISO(
                                        log.stopped_at,
                                        { locale : localizationStore.locale }
                                    ).toLocaleString(DateTime.TIME_24_SIMPLE)
                                }}
                                <c-tooltip class="ml-1" position="right"
                                           v-bind:active="tooltipTime === log.id + '_stopped'"
                                >
                                    {{
                                        DateTime.fromISO(
                                            log.stopped_at,
                                            { locale : localizationStore.locale }
                                        ).toFormat(localizationStore.dateFormat)
                                    }}
                                </c-tooltip>
                            </span>
                        </span>
                        <span class="font-bold"
                              v-if="!editedLog || editedLog !== log.id"
                        >
                            {{ log.time_for_humans }}
                            <c-icon class="cursor-pointer h-4 inline ml-2 stroke-highlight w-4" icon="edit-3"
                                    v-on:click="editedLog = log.id"
                            />
                        </span>
                        <div class="flex items-center text-sm"
                             v-if="editedLog === log.id"
                        >
                            <c-control-number class="w-12"
                                              name="log.hours"
                                              v-bind:min="0"
                                              v-model="log.hours"
                                              v-on:keyup.enter="updateTimeLog(log)"
                            />h
                            <c-control-number class="ml-2 w-12"
                                              name="log.minutes"
                                              v-model="log.minutes"
                                              v-bind:max="59"
                                              v-bind:min="0"
                                              v-on:keyup.enter="updateTimeLog(log)"
                            />'
                            <c-icon class="cursor-pointer h-4 inline ml-5 stroke-slate-500 w-4" icon="x"
                                    v-on:click="log.hours = log.hours_old; log.minutes = log.minutes_old; editedLog = null"
                            />
                            <c-icon class="cursor-pointer h-4 inline ml-1 stroke-success w-4" icon="check"
                                    v-on:click="updateTimeLog(log)"
                            />
                        </div>
                    </div>
                </div>

                <strong class="block text-right text-sm">{{ __('Total') }}</strong>
                <span class="block font-black text-2xl text-right">{{ worksheet.time_for_humans }}</span>
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" dusk="cancel-button" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" dusk="save-button" v-on:click="closeDestination">
                    {{ __('Close') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { computed, ref, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlNumber,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import { useLocalizationStore } from '../../../stores/localization';
    import { useRequestStore } from '../../../stores/request';
    import WorksheetRepository from '../../../repositories/WorksheetRepository';
    import WorksheetTimeRepository from '../../../repositories/WorksheetTimeRepository';

    const editedLog = ref<string>();
    const localizationStore = useLocalizationStore();
    const open = ref<boolean>(true);
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();

    const timeLogsGroupedByAssignee = computed(() => {
        if (!worksheet.value) {
            return [];
        }

        let logs = {};

        for (let log of worksheet.value.time_logs) {
            if (!logs[log.assignee.id]) {
                logs[log.assignee.id] = {
                    assignee: log.assignee,
                    items: [],
                };
            }

            log.duration = Math.floor(log.time / 60);
            log.hours = log.hours_old = Math.floor(log.duration / 60);
            log.minutes = log.minutes_old = log.duration % 60;

            logs[log.assignee.id].items.push(log);
        }

        return logs;
    });

    const tooltipTime = ref<string | null>(null);
    const worksheet = ref<object>();
    const worksheetId = route.params.worksheet;
    const worksheetRepository = WorksheetRepository.getInstance();
    const worksheetTimeRepository = WorksheetTimeRepository.getInstance();

    function closeDestination() {
        open.value = false;

        if (!window.history.state.back) {
            router.push({ name : 'tickets' });
        } else {
            router.go(-1);
        }
    }

    async function getWorksheet() {
        worksheet.value = await worksheetRepository.show(worksheetId, {
            include: [
                'time_logs.assignee',
            ]
        });
    }

    async function updateTimeLog(log) {
        await worksheetTimeRepository.update(log.id, {
            time: (log.hours * 60 + log.minutes) * 60,
        });

        editedLog.value = null;

        await getWorksheet();
    }

    onMounted(async () => {
        await getWorksheet();
    });
</script>
