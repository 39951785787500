import Repository from './Repository';

export default class WorksheetTimeRepository extends Repository {
    protected basePath = 'worksheets/time';

    protected messages = {
        update : this.__('Time entry successfully updated.'),
    }

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method does not exist on WorksheetTimeRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on WorksheetTimeRepository.');
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on WorksheetTimeRepository.');
    }

    public store(data: object, params: object = null) {
        console.error('Store method does not exist on WorksheetTimeRepository.');
    }
}