import Repository from './Repository';

export default class UserProfileRepository extends Repository {
    protected basePath = 'profile';

    protected messages = {
        update : this.__('Personal profile successfully updated.'),
    }

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method does not exist on UserProfileRepository.');
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on UserProfileRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on UserProfileRepository.');
    }

    public index(params: object = null): Promise {
        console.error('Index method does not exist on UserProfileRepository.');
    }

    public show(): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath))
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public store(data: object, params: object = null): Promise {
        console.error('Store method does not exist on UserProfileRepository.');
    }

    public update(data: object) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.put(this.buildPath(this.basePath), data)
                .then((response : any) => {
                    this.request.message = this.messages.update;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }
}