<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ partner.name }}</h1>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router v-bind:to="{ name : 'partners' }">
                    {{ __('Cancel') }}
                </c-button>

                <c-button class="bg-success lg:min-w-[200px] text-white"
                          dusk="save-button"
                          v-if="can('partners.update')"
                          v-on:click="submit"
                >
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-20"
                      v-else-if="can('partners.update')"
                      v-on:click="submit"
            >
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Partner details') }}</h2>
            <div class="grid lg:grid-cols-3 gap-10 mb-10">
                <c-control-text class="lg:col-span-2" name="partner.name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Name')"
                                v-bind:placeholder="__('placeholders.company_name')"
                                v-model="partner.name"
                />

                <c-control-text name="partner.tax_id"
                                v-bind:invalid="!!requestStore.errors?.tax_id"
                                v-bind:label="__('Tax ID')"
                                v-bind:placeholder="__('placeholders.tax_id')"
                                v-model="partner.tax_id"
                />
            </div>

            <div class="grid lg:grid-cols-3 gap-10 mb-10">
                <c-control-select name="country" required
                                  v-bind:invalid="!!requestStore.errors?.country"
                                  v-bind:label="__('Country')"
                                  v-bind:options="countries"
                                  v-model="partner.country"
                />

                <c-control-text name="postcode" required
                                v-bind:invalid="!!requestStore.errors?.postcode"
                                v-bind:label="__('Postcode')"
                                v-bind:placeholder="__('placeholders.postcode')"
                                v-if="partner.country === 'HU'"
                                v-model="partner.postcode"
                />

                <c-control-text name="county" required
                                v-bind:invalid="!!requestStore.errors?.county"
                                v-bind:label="__('County')"
                                v-bind:placeholder="__('placeholders.county')"
                                v-else
                                v-model="partner.county"
                />

                <c-control-text name="city" required
                                v-bind:invalid="!!requestStore.errors?.city"
                                v-bind:label="__('City')"
                                v-bind:placeholder="__('placeholders.city')"
                                v-model="partner.city"
                />
            </div>
            <div class="grid lg:grid-cols-2 gap-10">
            <c-control-text name="address_line_1" required
                            v-bind:invalid="!!requestStore.errors?.address_line_1"
                            v-bind:label="__('Address line 1')"
                            v-bind:placeholder="__('Street name, number')"
                            v-model="partner.address_line_1"
            />

            <c-control-text name="address_line_2"
                            v-bind:invalid="!!requestStore.errors?.address_line_2"
                            v-bind:label="__('Address line 2')"
                            v-bind:placeholder="__('Apartment, building, floor, suite')"
                            v-model="partner.address_line_2"
            />
        </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Contact person') }}</h2>
            <div class="grid lg:grid-cols-3 gap-10">
                <c-control-text name="partner.contact_name" required
                                v-bind:invalid="!!requestStore.errors?.contact_name"
                                v-bind:label="__('Name')"
                                v-bind:placeholder="__('placeholders.person_name')"
                                v-model="partner.contact_name"
                />

                <c-control-text name="partner.contact_email"
                                v-bind:invalid="!!requestStore.errors?.contact_email"
                                v-bind:label="__('Email')"
                                v-bind:placeholder="__('placeholders.email')"
                                v-model="partner.contact_email"
                />

                <c-control-text name="partner.contact_phone"
                                v-bind:invalid="!!requestStore.errors?.contact_phone"
                                v-bind:label="__('Phone number')"
                                v-bind:placeholder="__('placeholders.phone')"
                                v-model="partner.contact_phone"
                />
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <div class="flex items-center justify-between mb-5">
                <h2 class="font-medium text-lg">{{ __('Partner sites') }}</h2>

                <c-button class="bg-highlight shrink-0 text-white"
                          router
                          v-if="can('partners.update')"
                          v-bind:to="{ name : 'partners.sites.create' }"
                >
                    + {{ __('Add partner site') }}
                </c-button>
            </div>

            <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" dusk="partner-sites-list">
            <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm"
                draggable="true"
                v-bind:key="key"
                v-for="(site, key) in partner.sites"
                v-on:dragend="dragEnd($event)"
                v-on:dragenter.prevent="dragging(site, $event)"
                v-on:dragover.prevent
                v-on:dragstart.stop="dragStart(site, $event)"
                v-on:drop="dragDrop"
            >
                <div class="absolute bg-slate-200 border border-dotted border-highlight h-full hidden left-0 rounded top-0 w-full z-10"
                     v-bind:class="{ '!block' : dragEl && dragEl?.id === site.id }"
                />

                <span class="block border-dotted border-l-2 border-r-2 border-slate-500 cursor-grab h-2.5 ml-2 mr-0.5 shrink-0 w-1.5" />

                <router-link class="flex h-16 items-center justify-start pl-2 pr-8 py-2 w-full"
                             v-bind:to="{ name : 'partners.sites.edit', params : { site : site.id } }"
                             v-if="can('partners.update')"
                >
                    <span class="line-clamp-3 text-sm">{{ site.name }}</span>

                    <span class="absolute cursor-help right-1 top-1"
                          v-if="site.needs_maintenance"
                          v-on:mouseover="siteTooltip = site.id"
                          v-on:mouseout="siteTooltip = null"
                    >
                        <c-icon class="h-6 stroke-error w-6" icon="alert-triangle" />
                        <c-tooltip position="top" v-bind:active="siteTooltip === site.id">
                            {{ __('At least one device needs maintenance.') }}
                        </c-tooltip>
                    </span>
                </router-link>

                <div class="absolute -bottom-1 group/card-actions right-1"
                     dusk="kebab-menu"
                     v-if="can('partners.update')"
                >
                    <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                          dusk="site-actions"
                          v-on:click="showCardActions = []; showCardActions[site.id] = !showCardActions[site.id]"
                          v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                    >
                        <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                    </span>
                    <div class="absolute bg-slate-700 border border-slate-200 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                         v-bind:class="{ '!block' : showCardActions[site.id] }"
                    >
                        <router-link class="border-b border-b-slate-500 flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                     dusk="edit-site-button"
                                     v-bind:to="{ name : 'partners.sites.edit', params : { site : site.id } }"
                                     v-if="can('partners.update')"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                        </router-link>
                        <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs whitespace-nowrap"
                           dusk="delete-site-button"
                           v-if="can('partners.update')"
                           v-on:click="deletedSite = site.id"
                        >
                            <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                        </a>
                    </div>
                </div>
            </li>
            <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm">
                <router-link class="flex h-16 items-center justify-center p-2 w-full"
                             v-bind:to="{ name : 'partners.sites.create' }"
                             v-if="can('partners.update')"
                >
                    <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                </router-link>
            </li>
        </ul>
        </div>

        <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="fields.length > 0 && partner.fieldValues">
            <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

            <template v-for="field in fields">
                <div class="mt-5">
                    <div class="bg-indigo-50 mb-1 p-2 rounded"
                         v-for="repetition in computeFieldRepetitions(field)"
                         v-if="field.fields && field.fields.length > 0"
                    >
                        <div class="flex items-center justify-between mb-4">
                            <strong class="text-sm">{{ field.label }}</strong>
                            <a class="cursor-pointer"
                               v-if="repetition > 1"
                               v-on:click="deleteFieldRepetition(field, repetition)"
                            >
                                <c-icon class="h-6 stroke-error w-6" icon="trash-2" />
                            </a>
                        </div>
                        <div class="mb-5"
                             v-for="subfield in field.fields"
                        >
                            <custom-field v-bind:field="subfield"
                                          v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                          v-model="partner.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                            />
                        </div>
                        <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                           v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                           v-on:click="addFieldRepetition(field)"
                        >
                            <c-icon class="h-6 stroke-white w-6" icon="plus" />
                        </a>
                    </div>
                    <custom-field v-bind:field="field"
                                  v-bind:id="field.key"
                                  v-else
                                  v-model="partner.fieldValues[field.key]"
                    />
                </div>
            </template>
        </div>
    </div>

    <c-modal class="md:w-[450px]" v-bind:open="deletedSite !== null" v-bind:title="__('Delete partner site')" v-on:closeModal="deletedSite = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this partner site?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedSite = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" dusk="delete-contact-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../repositories/PartnerRepository';
    import PartnerSiteRepository from '../../repositories/PartnerSiteRepository';
    import { useGeoStore } from '../../stores/geo';
    import { useRequestStore } from '../../stores/request';

    import CustomField from '../../components/CustomField.vue';

    const countries = ref([]);
    const route = useRoute();
    const partnerId = route.params.partner;

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const deletedSite = ref(null);
    const dragEl = ref<object>();
    const dragDest = ref<object>();
    const dragImage = ref();
    const dragOrig = ref<object[]>([]);
    const dragOver = ref<object>();
    const dragPos = ref<number>();
    const fields = ref<Object[]>([]);
    const floatSave = ref<boolean>(false);
    const geoStore = useGeoStore();
    const partner = ref<object>({});
    const partnerRepository = PartnerRepository.getInstance();
    const partnerSiteRepository = PartnerSiteRepository.getInstance();
    const requestStore = useRequestStore();
    const showCardActions = ref<boolean[]>([]);
    const siteTooltip = ref<string | null>(null);

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            partner.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                partner_id: partner.value.id,
            });
        });

        computePartnerFieldValues();
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        partner.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computePartnerFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = partner.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        partner.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            partner_id: partner.value.id,
                        });
                    }
                });
            } else {
                const found = partner.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    partner.value.fields.push({
                        key: field.key,
                        value: null,
                        partner_id: partner.value.id,
                    });
                }
            }
        });

        partner.value.fieldValues = partner.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    async function del() {
        await partnerSiteRepository.destroy(deletedSite.value);

        let sitesTemp = partner.value.sites;
        partner.value.sites.forEach((partner, key) => {
            if (partner.id === deletedSite.value) {
                sitesTemp.splice(key, 1);
            }
        });
        partner.value.sites = sitesTemp;

        deletedSite.value = null;
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            partner.value.fields = partner.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = partner.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                partner.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computePartnerFieldValues();
    }

    async function dragDrop() {
        if (dragDest.value?.id !== dragEl.value.id) {
            await partnerSiteRepository.reorder({
                sites: partner.value.sites
            });
        }

        dragEl.value = null;
    }

    function dragEnd(e) {
        if (e.dataTransfer.dropEffect === 'none') {
            partner.value.sites = dragOrig.value;
        }

        dragImage.value.remove();
        dragEl.value = null;
        dragDest.value = null;
        dragOrig.value = [];
        dragOver.value = null;
        dragPos.value = null;
    }

    function dragging(site, e) {
        dragOver.value = site;

        const direction = e.clientX < dragPos.value ? 0 : 1;
        dragPos.value = e.clientX;

        partner.value.sites.every((b, key) => {
            if (
                site.id !== dragEl.value.id &&
                dragEl.value.id === b.id
            ) {
                partner.value.sites.splice(key, 1);

                return false;
            }

            return true;
        });

        partner.value.sites.every((b, key) => {
            if (
                site.id !== dragEl.value.id &&
                site.id === b.id
            ) {
                dragDest.value = site;
                partner.value.sites.splice(key + direction, 0, dragEl.value);

                return false;
            }

            return true;
        });
    }

    function dragStart(site, e) {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('id', site.id);

        dragEl.value = site;

        dragImage.value = document.createElement('div');
        const clone = e.target.cloneNode(true);
        dragOrig.value = JSON.parse(JSON.stringify(partner.value.sites));

        if (clone.style) {
            clone.style.backgroundColor = '#ffffff';
            clone.style.border = 'none';
            clone.style.listStyle = 'none';
            clone.style.transform = 'rotate(3deg)';
        }

        dragImage.value.style.left = '-9999px';
        dragImage.value.style.position = 'absolute';
        dragImage.value.style.top = '-9999px';
        dragImage.value.appendChild(clone);
        document.body.appendChild(dragImage.value);
        e.dataTransfer.setDragImage(dragImage.value, -100, -100);

        dragPos.value = e.clientX;
    }

    async function submit() {
        partner.value = await partnerRepository.update(partnerId, partner.value, {
            include: [
                'fields',
                'sites'
            ]
        });

        computePartnerFieldValues();
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();

        await requestStore.get('partners/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        partner.value = await partnerRepository.show(partnerId, {
            include: [
                'fields',
                'sites'
            ]
        });

        computePartnerFieldValues();

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });

    watch(() => partner.value, () => {
        Object.keys(partner.value.fieldValues).forEach(key => {
            let field = partner.value.fields.find(f => f.key === key);

            if (field) {
                field.value = partner.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
