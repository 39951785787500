import { inject } from 'vue';
import { defineStore } from 'pinia';

import { useRequestStore } from './request';

export const useGeoStore = defineStore('geo', () => {
    const __ = inject('__');
    const request = useRequestStore();

    function countries() {
        return new Promise((resolve, reject) => {
            request.get('geo/countries')
                .then((response : any) => {
                    resolve(
                        response.data.map((country) => {
                            return {
                                label : __('country.' + country['code']),
                                value : country['code'],
                            }
                        }).sort((country1, country2) => {
                           return country1.label.localeCompare(country2.label);
                        })
                    );
                })
                .catch(error => reject(error));
        });
    }

    return { countries }
});