import { ref } from 'vue';
import { defineStore } from 'pinia';

import { DateFormatEnum } from '../enums/DateFormatEnum';

export const useLocalizationStore = defineStore('localization', () => {
    // const locale = ref<string>(window.navigator.language.split('-')[0]);
    const locale = ref<string>('hu'); // @TODO make dependent on device language
    const dateFormat = ref<DateFormatEnum>(DateFormatEnum[locale.value] ?? DateFormatEnum.default);
    const dateTimeFormat = dateFormat.value + ' HH:mm';
    const strings = ref();

    function getStrings() {
        return new Promise((resolve, reject) => {
            fetch('/lang/' + locale.value + '.json')
                .then(response => response.json())
                .then(data => resolve(strings.value = data ));
        });
    }

    return {
        dateFormat,
        dateTimeFormat,
        getStrings,
        locale,
        strings
    }
});
