import Repository from './Repository';

export default class WorksheetInventoryRepository extends Repository {
    protected basePath = 'worksheets/inventory';

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method is not allowed on WorksheetInventoryRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on WorksheetInventoryRepository.');
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on WorksheetInventoryRepository.');
    }

    public store(data: object, params: object = null) {
        console.error('Store method is not allowed on WorksheetInventoryRepository.');
    }

    public update(id: string, data: object, params: object = null) {
        console.error('Update method does not exist on WorksheetInventoryRepository.');
    }
}