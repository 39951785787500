<template>
    <div class="container px-1 py-5" v-if="tenant.is_subscribed">
        <h1 class="lg:text-3xl mb-5 md:mb-10 mr-10 text-2xl">{{ __('Billing') }}</h1>

        <div class="grid grid-cols-2 gap-4">
            <h2 class="font-medium mb-5 text-lg">{{ __('Current subscription plan') }}</h2>

            <div class="bg-white border border-slate-200 rounded" v-if="!tenant.is_on_grace_period">
                <div class="pb-5 px-2 pt-2">
                    <div class="flex items-center justify-between mb-5">
                        <div class="flex flex-col">
                            <span class="capitalize text-sm">{{ subscription.product }}</span>
                            <span class="text-slate-400 text-sm">
                                {{ subscription.price === 'monthly' ? __('Monthly') : __('Yearly') }}
                            </span>
                        </div>
                        <div>
                            <a class="bg-slate-50 cursor-pointer font-medium mr-2 px-2 py-1 rounded text-error text-sm"
                               v-on:click="cancelSubscription"
                            >
                                {{ __('Cancel subscription') }}
                            </a>
                            <router-link class="bg-slate-50 font-medium px-2 py-1 rounded text-highlight text-sm"
                                         v-bind:to="{ name: 'subscriptions.change-plan' }"
                            >
                                {{ __('Change plan') }}
                            </router-link>
                        </div>
                    </div>

                    <span class="block mb-5"
                          v-html="__('Your next billing cycle is on :date and you will be charged for :amount.', {
                                date: '<strong>' + DateTime.fromSeconds(subscription.upcoming.date).toFormat(localizationStore.dateFormat) + '</strong>',
                                amount: '<strong>' + currencyStore.format(subscription.upcoming.amount, subscription.upcoming.currency) + '</strong>'
                            })"
                          v-if="subscription.upcoming.date"
                    />

                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Seats') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ seats }}</span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Price per seat') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">
                            {{ currencyStore.format(subscription.amount, subscription.currency) }}
                            /
                            <span v-text="subscription.interval === 'month' ? __('month') : __('year')"></span>
                        </span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Subscription price') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ currencyStore.format(subscriptionAmount, subscription.currency) }}</span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Balance') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ currencyStore.format(balanceAmount, subscription.currency) }}</span>
                    </span>
                    <span class="flex mt-5 text-sm">
                        <span class="font-semibold shrink-0 text-slate-500">{{ __('Total') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="font-semibold shrink-0">{{ currencyStore.format(subscription.upcoming.amount, subscription.upcoming.currency) }}</span>
                    </span>
                </div>
                <div class="border-t border-t-slate-200 flex items-center justify-between pb-2 px-2 pt-5"
                     v-if="tenant.pm_type"
                >
                    <div class="flex items-center">
                        <img class="h-8 mr-5" v-bind:src="'/img/pm/' + tenant.pm_type + '.png'" v-bind:alt="tenant.pm_type" />
                        <span class="text-slate-500 text-sm"
                              v-html="__(':type card ending with number :last_four', {
                                  type: '<span class=\'capitalize\'>' + tenant.pm_type + '</span>',
                                  last_four: tenant.pm_last_four
                              })"
                        />
                    </div>
                    <router-link class="bg-slate-50 font-medium px-2 py-1 rounded text-highlight text-sm"
                                 v-bind:to="{ name: 'subscriptions.update-payment-method' }"
                    >
                        {{ __('Update payment method') }}
                    </router-link>
                </div>
            </div>
            <div class="bg-white border border-slate-200 rounded" v-else>
                <div class="p-2">
                    <div class="flex items-center justify-between mb-5">
                        <div class="flex flex-col">
                            <span class="capitalize text-sm">{{ subscription.product }}</span>
                            <span class="text-slate-400 text-sm">
                                {{ subscription.price === 'monthly' ? __('Monthly') : __('Yearly') }}
                            </span>
                        </div>
                        <div>
                            <a class="bg-slate-50 cursor-pointer font-medium px-2 py-1 rounded text-highlight text-sm"
                               v-on:click="resumeSubscription"
                            >
                                {{ __('Resume subscription') }}
                            </a>
                        </div>
                    </div>

                    <span class="block" v-html="__('You are currently on your grace period. Please subscribe to continue using the product after :date.', {
                        date: '<strong>' + DateTime.fromSeconds(subscription.cancel_at).toFormat(localizationStore.dateFormat) + '</strong>'
                    })" />
                </div>
            </div>
        </div>
        <div class="border-t border-t-slate-200 grid grid-cols-2 gap-4 mt-5 pt-5"
             v-if="subscription.invoices && subscription.invoices.length > 0"
        >
            <h2 class="font-medium mb-5 text-lg">{{ __('Payment history') }}</h2>

            <div class="bg-white border border-slate-200 rounded">
                <div class="px-2 pt-2">
                    <table class="w-full">
                        <thead>
                            <tr class="text-left">
                                <th class="font-normal pb-2 text-slate-500 text-xs">{{ __('Invoice number') }}</th>
                                <th class="font-normal pb-2 text-slate-500 text-xs">{{ __('Date') }}</th>
                                <th class="font-normal pb-2 text-slate-500 text-xs">{{ __('Amount') }}</th>
                                <th class="font-normal pb-2 text-slate-500 text-xs">{{ __('Status') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-t border-t-slate-200" v-for="invoice in subscription.invoices">
                                <td class="py-1 text-xs">{{ invoice.number }}</td>
                                <td class="py-1 text-xs">
                                    {{ DateTime.fromSeconds(invoice.date).toFormat(localizationStore.dateFormat) }}
                                </td>
                                <td class="py-1 text-xs">{{ currencyStore.format(invoice.amount, invoice.currency) }}</td>
                                <td class="py-1"
                                >
                                    <span class="p-1 rounded text-xs"
                                        v-bind:class="invoiceStatusColour(invoice.status)">
                                        {{ __('invoice.' + invoice.status) }}
                                    </span>
                                </td>
                                <td class="py-1 text-right text-xs">
                                    <a class="text-highlight" target="_blank"
                                       v-bind:href="invoice.url"
                                       v-if="invoice.url"
                                    >
                                        {{ __('View invoice') }}
                                    </a>
                                    <span v-else>{{ __('Invoice is being prepared') }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { DateTime } from 'luxon';

    import EmployeeRepository from '../../repositories/EmployeeRepository';

    import { useCurrencyStore } from '../../stores/currency';
    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';

    const __ = inject('__');

    const balanceAmount = computed(() => {
        return subscription.upcoming.amount - subscriptionAmount.value;
    });

    const currencyStore = useCurrencyStore();
    const employeeRepository = EmployeeRepository.getInstance();
    const localizationStore = useLocalizationStore();
    const requestStore = useRequestStore();
    const router = useRouter();
    const seats = ref<number>(1);
    const sessionStore = useSessionStore();

    const subscriptionAmount = computed(() => {
        return subscription.quantity * subscription.amount;
    });

    const tenant = sessionStore.user.tenant;
    const subscription = tenant.subscription_plan;

    async function cancelSubscription() {
        await requestStore.post('subscriptions/cancel').then(async () => {
            await sessionStore.refreshUser();

            requestStore.redirect({ name: 'subscriptions.billing' }, {
                message: __('Subscription successfully cancelled.'),
                status: 200,
                title: __('Success'),
            });
        });
    }

    function invoiceStatusColour(status) {
        return {
            'bg-red-100 text-red-400': status === 'deleted',
            'bg-slate-100 text-slate-500': status === 'draft',
            'bg-indigo-50 text-indigo-400': status === 'open',
            'bg-emerald-50 text-emerald-600': status === 'paid',
            'bg-amber-50 text-amber-600': status === 'uncollectible',
            'bg-stone-100 text-stone-500': status === 'void',
        };
    }

    async function resumeSubscription() {
        await requestStore.post('subscriptions/resume').then(async () => {
            await sessionStore.refreshUser();

            requestStore.redirect({ name: 'subscriptions.billing' }, {
                message: __('Subscription successfully resumed.'),
                status: 200,
                title: __('Success'),
            });
        });
    }

    onMounted(async () => {
        if (!tenant.is_subscribed) {
            router.push({ name : 'subscriptions.subscribe' })
        }

        sessionStore.refreshUser();

        seats.value = await employeeRepository.index().then((employees) => {
            return employees.length;
        });
    });
</script>