<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ __('Company profile') }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" v-on:click="closeDestination">{{ __('Cancel') }}</c-button>

                <c-button class="bg-success lg:min-w-[200px] text-white"
                          v-if="can('profile.company.update')"
                          v-on:click="submit"
                >{{ __('Save') }}</c-button>
            </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-10 mb-10">
            <c-control-text name="company.name" required
                            v-bind:invalid="!!requestStore.errors?.name"
                            v-bind:label="__('Name')"
                            v-bind:placeholder="__('placeholders.company_name')"
                            v-model="company.name"
            />

            <c-control-text name="company.tax_id"
                            v-bind:label="__('Tax ID')"
                            v-bind:placeholder="__('placeholders.tax_id')"
                            v-model="company.tax_id"
            />
        </div>

        <div class="grid lg:grid-cols-3 gap-10 mb-10">
            <c-control-select required
                              v-bind:invalid="!!requestStore.errors?.country"
                              v-bind:label="__('Country')"
                              v-bind:name="'company.country'"
                              v-bind:options="countries"
                              v-model="company.country"
            />

            <c-control-text required
                            v-bind:invalid="!!requestStore.errors?.postcode"
                            v-bind:label="__('Postcode')"
                            v-bind:name="'company.postcode'"
                            v-bind:placeholder="__('placeholders.postcode')"
                            v-if="company.country === 'HU'"
                            v-model="company.postcode"
            />

            <c-control-text required
                            v-bind:invalid="!!requestStore.errors?.county"
                            v-bind:label="__('County')"
                            v-bind:name="'company.county'"
                            v-bind:placeholder="__('placeholders.county')"
                            v-else
                            v-model="company.county"
            />

            <c-control-text required
                            v-bind:invalid="!!requestStore.errors?.city"
                            v-bind:label="__('City')"
                            v-bind:name="'company.city'"
                            v-bind:placeholder="__('placeholders.city')"
                            v-model="company.city"
            />
        </div>

        <div class="grid lg:grid-cols-2 gap-10 mb-10">
            <c-control-text required
                            v-bind:invalid="!!requestStore.errors?.address_line_1"
                            v-bind:label="__('Address line 1')"
                            v-bind:name="'company.address_line_1'"
                            v-bind:placeholder="__('Street name, number')"
                            v-model="company.address_line_1"
            />

            <c-control-text v-bind:label="__('Address line 2')"
                            v-bind:name="'company.address_line_2'"
                            v-bind:placeholder="__('Apartment, building, floor, suite')"
                            v-model="company.address_line_2"
            />
        </div>

        <div class="grid lg:grid-cols-2 gap-10 mb-10">
            <c-control-text name="company.email" required
                            v-bind:invalid="!!requestStore.errors?.email"
                            v-bind:label="__('Email')"
                            v-bind:placeholder="__('placeholders.email')"
                            v-model="company.email"
            />

            <c-control-text name="company.phone"
                            v-bind:label="__('Phone number')"
                            v-bind:placeholder="__('placeholders.phone')"
                            v-model="company.phone"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import {
        CButton,
        CControlSelect,
        CControlText,
    } from '@teamfurther/cinderblock';

    import CompanyProfileRepository from '../../repositories/CompanyProfileRepository';
    import { useGeoStore } from '../../stores/geo';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const companyProfileRepository = CompanyProfileRepository.getInstance();
    const countries = ref([]);
    const geoStore = useGeoStore();
    const requestStore = useRequestStore();
    const router = useRouter();
    const sessionStore = useSessionStore();
    const tenant = sessionStore.user['tenant'];

    const company = ref<object>({
        address_line_1: tenant?.address_line_1,
        address_line_2: tenant?.address_line_2,
        city: tenant?.city,
        country: tenant?.country,
        county: tenant?.county,
        email: tenant?.email,
        name: tenant?.name,
        phone: tenant?.phone,
        postcode: tenant?.postcode,
        tax_id: tenant?.tax_id,
    });

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'tickets' });
        } else {
            router.go(-1);
        }
    }

    async function submit() {
        await companyProfileRepository.update(company.value);

        sessionStore.refreshUser();
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();

        const companyProfile = await companyProfileRepository.show();

        company.value = { ...companyProfile };
    });
</script>
