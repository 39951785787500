<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ product.name }}</h1>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 lg:min-w-[200px] mr-2 text-slate-500" dusk="cancel-button" router
                          v-bind:to="{ name : 'inventory' }"
                >
                    {{ __('Cancel') }}
                </c-button>

                <c-button class="bg-success lg:min-w-[200px] text-white"
                          dusk="save-button"
                          v-if="can('inventory.update')"
                          v-on:click="submit"
                >
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-20"
                      v-else-if="can('inventory.update')"
                      v-on:click="submit"
            >
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Product details') }}</h2>
            <div class="grid lg:grid-cols-2 gap-10 mb-10">
                <c-control-text name="product.code" required
                                v-bind:invalid="!!requestStore.errors?.code"
                                v-bind:label="__('Article number')"
                                v-model="product.code"
                />

                <c-control-text name="product.name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Name')"
                                v-model="product.name"
                />
            </div>

            <div class="grid lg:grid-cols-2 gap-10 mb-10">
                <c-control-text name="product.quantity" required
                                v-bind:invalid="!!requestStore.errors?.quantity"
                                v-bind:label="__('Quantity')"
                                v-model="product.quantity"
                />

                <c-control-select name="product.um" required
                                  v-bind:invalid="!!requestStore.errors?.um"
                                  v-bind:label="__('Unit')"
                                  v-bind:options="unitOptions"
                                  v-model="product.um"
                />
            </div>

            <div class="grid lg:grid-cols-2 gap-10">
                <c-control-text name="product.cost_price"
                                v-bind:invalid="!!requestStore.errors?.cost_price"
                                v-bind:label="__('Cost price')"
                                v-model="product.cost_price"
                />

                <c-control-text name="product.sale_price"
                                v-bind:invalid="!!requestStore.errors?.sale_price"
                                v-bind:label="__('Sale price')"
                                v-model="product.sale_price"
                />
            </div>
        </div>

        <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="fields.length > 0 && product.fieldValues">
            <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

            <template v-for="field in fields">
                <div class="mt-5">
                    <div class="bg-indigo-50 mb-1 p-2 rounded"
                         v-for="repetition in computeFieldRepetitions(field)"
                         v-if="field.fields && field.fields.length > 0"
                    >
                        <div class="flex items-center justify-between mb-4">
                            <strong class="text-sm">{{ field.label }}</strong>
                            <a class="cursor-pointer"
                               v-if="repetition > 1"
                               v-on:click="deleteFieldRepetition(field, repetition)"
                            >
                                <c-icon class="h-6 stroke-error w-6" icon="trash-2" />
                            </a>
                        </div>
                        <div class="mb-5"
                             v-for="subfield in field.fields"
                        >
                            <custom-field v-bind:field="subfield"
                                          v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                          v-model="product.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                            />
                        </div>
                        <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                           v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                           v-on:click="addFieldRepetition(field)"
                        >
                            <c-icon class="h-6 stroke-white w-6" icon="plus" />
                        </a>
                    </div>
                    <custom-field v-bind:field="field"
                                  v-bind:id="field.key"
                                  v-else
                                  v-model="product.fieldValues[field.key]"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {inject, onMounted, ref, watch} from 'vue';
    import { useRoute } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';

    import InventoryRepository from '../../repositories/InventoryRepository';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    import CustomField from '../../components/CustomField.vue';

    const __ = inject('__');
    const countries = ref([]);
    const fields = ref<Object[]>([]);
    const floatSave = ref<boolean>(false);
    const inventoryRepository = InventoryRepository.getInstance();
    const product = ref<object>({});
    const requestStore = useRequestStore();
    const route = useRoute();
    const productId = route.params.inventory;
    const sessionStore = useSessionStore();
    const unitOptions = ref<object[]>([]);

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            product.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                inventory_id: product.value.id,
            });
        });

        computeProductFieldValues();
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        product.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computeProductFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = product.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        product.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            inventory_id: product.value.id,
                        });
                    }
                });
            } else {
                const found = product.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    product.value.fields.push({
                        key: field.key,
                        value: null,
                        inventory_id: product.value.id,
                    });
                }
            }
        });

        product.value.fieldValues = product.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            product.value.fields = product.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = product.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                product.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computeProductFieldValues();
    }

    async function submit() {
        product.value = await inventoryRepository.update(productId, product.value, {
            include: ['fields']
        });

        computeProductFieldValues();
    }

    onMounted(async () => {
        unitOptions.value = sessionStore.user.tenant.config.units.split(', ').map(unit => {
            return {
                label: __('units.' + unit),
                value: unit,
            }
        });

        await requestStore.get('inventory/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        product.value = await inventoryRepository.show(productId, {
            include: ['fields']
        });

        computeProductFieldValues();

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });

    watch(() => product.value, () => {
        Object.keys(product.value.fieldValues).forEach(key => {
            let field = product.value.fields.find(f => f.key === key);

            if (field) {
                field.value = product.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
