import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia';

import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance';
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { __ } from './mixins/__';
import { can } from './mixins/can';
import { hasRole } from './mixins/hasRole';
import { hasSubscription } from './mixins/hasSubscription';
import { isImage } from './mixins/isImage';
import { routes } from './routes';

import App from './App.vue';
import '/node_modules/@teamfurther/cinderblock/dist/cinderblock.css';

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_APP_VERSION,
    onError: function (event) {
        if (
            event.errors[0].status === 401 ||
            // event.errors[0].status === 402 ||
            // event.errors[0].status === 403 ||
            event.errors[0].status === 404 ||
            event.errors[0].status === 422
        ) {
            return false;
        }

        if (window.localStorage.getItem('user')) {
            const user = JSON.parse(window.localStorage.getItem('user'));
            event.setUser(user.id, user.email, user.name)
        }
    },
    plugins: [new BugsnagPluginVue()]
});
BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY })
const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App);
const router = createRouter({
    history: createWebHistory(),
    routes
});
const pinia = createPinia();

app.config.globalProperties.app = {
    name: import.meta.env.VITE_APP_NAME,
    version: import.meta.env.VITE_APP_VERSION,
}

app.use(bugsnagVue);
app.use(pinia);
app.use(router);

app.use(__);
app.use(can);
app.use(hasRole);
app.use(hasSubscription);
app.use(isImage);
app.provide('__', app.config.globalProperties.__);
app.provide('can', app.config.globalProperties.can);
app.provide('hasRole', app.config.globalProperties.hasRole);
app.provide('hasSubscription', app.config.globalProperties.hasSubscription);
app.provide('isImage', app.config.globalProperties.isImage);

app.mount('#app');