import Board from './views/tickets/Board.vue';
import E402 from './views/errors/402.vue';
import E403 from './views/errors/403.vue';
import E404 from './views/errors/404.vue';
import DevicesSnCreate from './views/devices/sn/Create.vue';
import DevicesSnEdit from './views/devices/sn/Edit.vue';
import DevicesPnIndex from './views/devices/pn/Index.vue';
import DevicesPnCreate from './views/devices/pn/Create.vue';
import DevicesPnEdit from './views/devices/pn/Edit.vue';
import EmployeesIndex from './views/employees/Index.vue';
import EmployeesCreate from './views/employees/Create.vue';
import EmployeesEdit from './views/employees/Edit.vue';
import ForgotPassword from './views/auth/ForgotPassword.vue';
import InventoryIndex from './views/inventory/Index.vue';
import InventoryCreate from './views/inventory/Create.vue';
import InventoryEdit from './views/inventory/Edit.vue';
import Login from './views/auth/Login.vue';
import PartnersIndex from './views/partners/Index.vue';
import PartnersCreate from './views/partners/Create.vue';
import PartnersEdit from './views/partners/Edit.vue';
import PartnerSitesCreate from './views/partners/sites/Create.vue';
import PartnerSitesEdit from './views/partners/sites/Edit.vue';
import PartnerSiteContactsCreate from './views/partners/sites/contacts/Create.vue';
import PartnerSiteContactsEdit from './views/partners/sites/contacts/Edit.vue';
import ProfileCompany from './views/profile/Company.vue';
import ProfileUser from './views/profile/User.vue';
import Register from './views/auth/Register.vue';
import ReportsCapacity from './views/reports/Capacity.vue';
import ReportsIndex from './views/reports/Index.vue';
import ReportsInventory from './views/reports/Inventory.vue';
import ReportsTime from './views/reports/Time.vue';
import ResetPassword from './views/auth/ResetPassword.vue';
import ResetPasswordCode from './views/auth/ResetPasswordCode.vue';
import SubscriptionsBilling from './views/subscriptions/Billing.vue';
import SubscriptionsChangePlan from './views/subscriptions/ChangePlan.vue';
import SubscriptionsSubscribe from './views/subscriptions/Subscribe.vue';
import SubscriptionsUpdatePaymentMethod from './views/subscriptions/UpdatePaymentMethod.vue';
import TicketsArchive from './views/tickets/Archive.vue';
import TicketsCreate from './views/tickets/Create.vue';
import TicketsEdit from './views/tickets/Edit.vue';
import TicketsMe from './views/tickets/Me.vue';
import TicketsMeEdit from './views/tickets/MeEdit.vue';
import TicketsSubmit from './views/front/tickets/Submit.vue';
import WorksheetsEdit from './views/worksheets/Edit.vue';
import WorksheetsShow from './views/worksheets/Show.vue';
import WorksheetsTimeEdit from './views/worksheets/time/Edit.vue';

export const routes = [
    {
        component: TicketsSubmit,
        meta: {
            hideAppBar: true,
            requiresAuth: false
        },
        name: 'front.tickets.submit',
        path: '/tickets/submit/:tenant',
    },
    {
        component: Board,
        meta: {
            permission: 'tickets.index.all|tickets.index.owned|tickets.index.assigned',
            requiresAuth: true
        },
        name: 'tickets',
        path: '/tickets',
    },
    {
        component: Register,
        meta: {
            requiresAuth: false
        },
        name: 'register',
        path: '/register',
    },
    {
        component: Login,
        meta: {
            requiresAuth: false
        },
        name: 'login',
        path: '/login/:token?',
    },
    {
        component: ForgotPassword,
        meta: {
            requiresAuth: false
        },
        name: 'forgot-password',
        path: '/password/reset',
    },
    {
        component: ResetPasswordCode,
        meta: {
            requiresAuth: false
        },
        name: 'password-email',
        path: '/password/email',
    },
    {
        component: ResetPassword,
        meta: {
            requiresAuth: false
        },
        name: 'reset-password',
        path: '/password/reset/:token',
    },
    {
        component: DevicesPnIndex,
        meta: {
            permission: 'devices.index',
            requiresAuth: true,
        },
        name: 'devices.pn',
        path: '/devices/pn',
    },
    {
        component: DevicesPnCreate,
        meta: {
            permission: 'devices.store',
            requiresAuth: true,
        },
        name: 'devices.pn.create',
        path: '/devices/pn/create',
    },
    {
        component: DevicesPnEdit,
        meta: {
            permission: 'devices.show|devices.update',
            requiresAuth: true,
        },
        name: 'devices.pn.edit',
        path: '/devices/pn/:pn/edit',
    },
    {
        component: DevicesSnCreate,
        meta: {
            permission: 'devices.store',
            requiresAuth: true,
        },
        name: 'devices.sn.create',
        path: '/devices/pn/:pn/sn/create',
    },
    {
        component: DevicesSnEdit,
        meta: {
            permission: 'devices.show|devices.update',
            requiresAuth: true,
        },
        name: 'devices.sn.edit',
        path: '/devices/pn/:pn/sn/:sn/edit',
    },
    {
        component: EmployeesIndex,
        meta: {
            permission: 'users.index',
            requiresAuth: true,
        },
        name: 'employees',
        path: '/employees',
    },
    {
        component: EmployeesCreate,
        meta: {
            permission: 'users.store',
            requiresAuth: true,
        },
        name: 'employees.create',
        path: '/employees/create',
    },
    {
        component: EmployeesEdit,
        meta: {
            permission: 'users.show|users.update',
            requiresAuth: true,
        },
        name: 'employees.edit',
        path: '/employees/:employee/edit',
    },
    {
        component: InventoryIndex,
        meta: {
            permission: 'inventory.index',
            requiresAuth: true,
        },
        name: 'inventory',
        path: '/inventory',
    },
    {
        component: InventoryCreate,
        meta: {
            permission: 'inventory.store',
            requiresAuth: true,
        },
        name: 'inventory.create',
        path: '/inventory/create',
    },
    {
        component: InventoryEdit,
        meta: {
            permission: 'inventory.update',
            requiresAuth: true,
        },
        name: 'inventory.edit',
        path: '/inventory/:inventory/edit',
    },
    {
        component: PartnersIndex,
        meta: {
            permission: 'partners.index',
            requiresAuth: true,
        },
        name: 'partners',
        path: '/partners',
    },
    {
        component: PartnersCreate,
        meta: {
            permission: 'partners.store',
            requiresAuth: true,
        },
        name: 'partners.create',
        path: '/partners/create',
    },
    {
        component: PartnersEdit,
        meta: {
            permission: 'partners.show|partners.update',
            requiresAuth: true,
        },
        name: 'partners.edit',
        path: '/partners/:partner/edit',
    },
    {
        component: PartnerSitesCreate,
        meta: {
            permission: 'partners.update',
            requiresAuth: true,
        },
        name: 'partners.sites.create',
        path: '/partners/:partner/sites/create',
    },
    {
        component: PartnerSitesEdit,
        meta: {
            permission: 'partners.update',
            requiresAuth: true,
        },
        name: 'partners.sites.edit',
        path: '/partners/:partner/sites/:site/edit',
    },
    {
        component: PartnerSiteContactsCreate,
        meta: {
            permission: 'partners.update',
            requiresAuth: true,
        },
        name: 'partners.sites.contacts.create',
        path: '/partners/:partner/sites/:site/contacts/create',
    },
    {
        component: PartnerSiteContactsEdit,
        meta: {
            permission: 'partners.update',
            requiresAuth: true,
        },
        name: 'partners.sites.contacts.edit',
        path: '/partners/:partner/sites/:site/contacts/:contact/edit',
    },
    {
        component: ProfileCompany,
        meta: {
            permission: 'profile.company.show',
            requiresAuth: true,
        },
        name: 'profile.company',
        path: '/profile/company',
    },
    {
        component: ProfileUser,
        meta: {
            requiresAuth: true
        },
        name: 'profile.user',
        path: '/profile',
    },
    {
        component: ReportsIndex,
        meta: {
            permission: 'worksheets.inventory.index|worksheets.time.index',
            requiresAuth: true,
        },
        name: 'reports',
        path: '/reports',
    },
    {
        component: ReportsCapacity,
        meta: {
            permission: 'worksheets.time.index',
            requiresAuth: true,
        },
        name: 'reports.capacity',
        path: '/reports/capacity',
    },
    {
        component: ReportsInventory,
        meta: {
            permission: 'worksheets.inventory.index',
            requiresAuth: true,
        },
        name: 'reports.inventory',
        path: '/reports/inventory',
    },
    {
        component: ReportsTime,
        meta: {
            permission: 'worksheets.time.index',
            requiresAuth: true,
        },
        name: 'reports.time',
        path: '/reports/time',
    },
    {
        component: SubscriptionsBilling,
        meta: {
            permission: 'subscriptions.manage',
            requiresAuth: true
        },
        name: 'subscriptions.billing',
        path: '/subscriptions/billing',
    },
    {
        component: SubscriptionsChangePlan,
        meta: {
            permission: 'subscriptions.manage',
            requiresAuth: true
        },
        name: 'subscriptions.change-plan',
        path: '/subscriptions/change-plan',
    },
    {
        component: SubscriptionsSubscribe,
        meta: {
            permission: 'subscriptions.manage',
            requiresAuth: true
        },
        name: 'subscriptions.subscribe',
        path: '/subscriptions/subscribe',
    },
    {
        component: SubscriptionsUpdatePaymentMethod,
        meta: {
            permission: 'subscriptions.manage',
            requiresAuth: true
        },
        name: 'subscriptions.update-payment-method',
        path: '/subscriptions/update-payment-method',
    },
    {
        component: TicketsMe,
        meta: {
            permission: 'tickets.index.all|tickets.index.owned',
            requiresAuth: true
        },
        name: 'tickets.me',
        path: '/tickets/me',
    },
    {
        component: TicketsMeEdit,
        meta: {
            permission: 'tickets.update.all|tickets.update.owned',
            requiresAuth: true
        },
        name: 'tickets.me.edit',
        path: '/tickets/:ticket/me',
    },
    {
        component: TicketsArchive,
        meta: {
            permission: 'tickets.archive.index.all|tickets.archive.index.owned',
            requiresAuth: true
        },
        name: 'tickets.archive',
        path: '/tickets/archive',
    },
    {
        component: TicketsCreate,
        meta: {
            permission: 'tickets.store',
            requiresAuth: true
        },
        name: 'tickets.create',
        path: '/tickets/create',
    },
    {
        component: TicketsEdit,
        meta: {
            permission: 'tickets.update.all|tickets.update.owned|tickets.update.assigned',
            requiresAuth: true
        },
        name: 'tickets.edit',
        path: '/tickets/:ticket/edit',
    },
    {
        component: WorksheetsEdit,
        meta: {
            permission: 'worksheets.update',
            requiresAuth: true
        },
        name: 'worksheets.edit',
        path: '/worksheets/:worksheet/edit',
    },
    {
        component: WorksheetsShow,
        meta: {
            permission: 'worksheets.show',
            requiresAuth: true
        },
        name: 'worksheets.show',
        path: '/worksheets/:worksheet',
    },
    {
        component: WorksheetsTimeEdit,
        meta: {
            permission: 'worksheets.update',
            requiresAuth: true
        },
        name: 'worksheets.time',
        path: '/worksheets/:worksheet/time',
    },
    {
        component: E402,
        name: 'errors.402',
        path: '/payment-required',
    },
    {
        component: E403,
        name: 'errors.403',
        path: '/unauthorized',
    },
    {
        component: E404,
        meta: {
            requiresAuth: false
        },
        name: 'errors.404',
        path: '/:pathMatch(.*)*',
    },
];
