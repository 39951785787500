<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ __('Personal profile') }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" v-on:click="closeDestination">{{ __('Cancel') }}</c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" v-on:click="submit">{{ __('Save') }}</c-button>
            </div>
        </div>

        <h2 class="font-medium mb-5 text-lg">{{ __('Modify personal data') }}</h2>
        <c-control-text class="mb-10" name="user.email" required
                        v-bind:invalid="!!requestStore.errors?.email"
                        v-bind:label="__('Email')"
                        v-bind:placeholder="__('placeholders.email')"
                        v-model="user.email"
        />

        <c-control-text class="mb-10" name="user.name" required
                        v-bind:invalid="!!requestStore.errors?.name"
                        v-bind:label="__('Name')"
                        v-bind:placeholder="__('placeholders.person_name')"
                        v-model="user.name"
        />

        <h2 class="font-medium mb-5 text-lg">{{ __('Change password') }}</h2>
        <c-control-text class="mb-10" name="user.password" placeholder="**********" required type="password"
                        v-bind:invalid="!!requestStore.errors?.password"
                        v-bind:label="__('New password')"  v-model="user.password"
        />

        <c-control-text name="user.passwordConfirmation" placeholder="**********" required type="password"
                        v-bind:label="__('Confirm password')"  v-model="user.passwordConfirmation"
        />
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import {
        CButton,
        CControlText,
    } from '@teamfurther/cinderblock';

    import UserProfileRepository from '../../repositories/UserProfileRepository';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';

    const requestStore = useRequestStore();
    const router = useRouter();
    const sessionStore = useSessionStore();

    const user = ref<object>({
        email: sessionStore.user.email,
        name: sessionStore.user.name,
        password: undefined,
        passwordConfirmation: undefined,
    });

    const userProfileRepository = UserProfileRepository.getInstance();

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'tickets' });
        } else {
            router.go(-1);
        }
    }

    async function submit() {
        await userProfileRepository.update({
            email: user.value.email,
            name: user.value.name,
            password: user.value.password,
            password_confirmation: user.value.passwordConfirmation,
        });

        sessionStore.refreshUser();
    }

    onMounted(async () => {
        const profile = await userProfileRepository.show();

        user.value.email = profile.email;
        user.value.name = profile.name;
    });
</script>
