<template>
    <div class="bg-highlight min-h-screen">
        <img class="bg-cover fixed h-screen hidden left-0 md:block pointer-events-none top-0"
             src="/img/logo-backdrop.svg"
             v-bind:alt="app.name"
        />

        <div class="container">
            <div class="px-2 py-8">
                <img class="h-6 mx-auto mb-10" src="/img/logo-white.svg" v-bind:alt="app.name" />

                <h1 class="font-bold mb-10 text-xl text-center text-white" v-if="tenant">
                    {{ __('Create new ticket for :tenant', { tenant: tenant.name }) }}
                </h1>

                <div class="gap-4 grid-cols-12 mb-8 md:border-l md:border-l-4 md:grid md:mb-16 md:pl-4 md:py-2"
                     v-if="!partner"
                >
                    <h2 class="col-span-12 mb-4 text-lg text-white">{{ __('Your information') }}</h2>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Your name') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.contact_name"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.contact_name'] }"
                               v-bind:placeholder="__('placeholders.person_name')"
                               v-model="ticket.partner.contact_name"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Your email address') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.contact_email"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.contact_email'] }"
                               v-bind:placeholder="__('placeholders.email')"
                               v-model="ticket.partner.contact_email"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Your phone number') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.contact_phone"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.contact_phone'] }"
                               v-bind:placeholder="__('placeholders.phone')"
                               v-model="ticket.partner.contact_phone"
                        />
                    </label>

                    <label class="block col-span-12 cursor-pointer flex items-center mb-4 md:mb-0 mt-8 text-sm text-white">
                        <input class="mr-2 scale-150" name="ticket.partner.type" type="checkbox" value="company"
                               v-model="ticket.partner.is_company"
                        />
                        {{ __('I am a business') }}
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('Company name') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.company_name"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.company_name'] }"
                               v-bind:placeholder="__('placeholders.company_name')"
                               v-model="ticket.partner.company_name"
                        />
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('Tax ID') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.tax_id"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.tax_id'] }"
                               v-bind:placeholder="__('placeholders.tax_id')"
                               v-model="ticket.partner.tax_id"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('Country') }} <span class="text-red-400">*</span>
                        <select class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                                name="ticket.partner.country"
                                v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.country'] }"
                                v-model="ticket.partner.country"
                        >
                                <option
                                    v-bind:value="country.value"
                                    v-for="country in countries"
                                >
                                    {{ country.label }}
                                </option>
                        </select>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white"
                           v-if="ticket.partner.is_company && ticket.partner.country !== 'HU'"
                    >
                        {{ __('County') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.county"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.county'] }"
                               v-bind:placeholder="__('placeholders.county')"
                               v-model="ticket.partner.county"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white"
                           v-if="ticket.partner.is_company && ticket.partner.country === 'HU'"
                    >
                        {{ __('Postcode') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.postcode"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.postcode'] }"
                               v-bind:placeholder="__('placeholders.postcode')"
                               v-model="ticket.partner.postcode"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('City') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.city"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.city'] }"
                               v-bind:placeholder="__('placeholders.city')"
                               v-model="ticket.partner.city"
                        />
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('Address line 1') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.address_line_1"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.address_line_1'] }"
                               v-bind:placeholder="__('Street name, number')"
                               v-model="ticket.partner.address_line_1"
                        />
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white" v-if="ticket.partner.is_company">
                        {{ __('Address line 2') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.address_line_2"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.address_line_2'] }"
                               v-bind:placeholder="__('Apartment, building, floor, suite')"
                               v-model="ticket.partner.address_line_2"
                        />
                    </label>
                </div>

                <div class="gap-4 grid-cols-12 mb-8 md:border-l md:border-l-4 md:grid md:mb-16 md:pl-4 md:py-2"
                     v-else
                >
                    <h2 class="col-span-12 mb-4 text-lg text-white">{{ __('Your information') }}</h2>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Name') }}
                        <span class="block font-mono underline">{{ partner.name }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Address') }}
                        <span class="block font-mono underline">{{ partner.address_line_1 }}</span>
                        <span class="block font-mono underline" v-if="partner.address_line_2">
                            {{ partner.address_line_2 }}
                        </span>
                        <span class="block font-mono underline">
                            <span v-if="partner.postcode">{{ partner.postcode }} </span>
                            {{ partner.city }},
                            {{ partner.country }}
                        </span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Tax ID') }}
                        <span class="block font-mono underline" v-if="partner.tax_id">{{ partner.tax_id }}</span>
                        <span class="block" v-else>-</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Contact person') }}
                        <span class="block font-mono underline">{{ partner.contact_name }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Email') }}
                        <span class="block font-mono underline" v-if="partner.contact_email">{{ partner.contact_email }}</span>
                        <span class="block" v-else>-</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Phone number') }}
                        <span class="block font-mono underline" v-if="partner.contact_phone">{{ partner.contact_phone }}</span>
                        <span class="block" v-else>-</span>
                    </label>
                </div>

                <div class="gap-4 grid-cols-12 mb-16 md:border-l md:border-l-4 md:grid md:pl-4 md:py-2"
                     v-if="!partnerSite"
                >
                    <h2 class="col-span-12 text-lg text-white">
                        {{ __('Location information (where device is found)') }}
                    </h2>

                    <label class="block col-span-12 cursor-pointer mb-8 text-sm text-white"
                           v-if="partner?.sites.length > 1"
                    >
                        <select class="p-1 rounded text-slate-800 text-sm"
                                v-model="ticket.partner_site_id"
                                v-on:change="setSiteAddress"
                        >
                            <option v-bind:value="null">{{ __('Select an existing site') }}</option>
                            <option v-for="site in partner.sites" v-bind:value="site.id">{{ site.name }}</option>
                        </select>
                    </label>

                    <label class="block col-span-12 cursor-pointer flex items-center mb-4 md:mb-0 text-sm text-white"
                           v-if="ticket.partner.is_company"
                    >
                        <input class="mr-2 scale-150" name="ticket.partner.type" type="checkbox" value="company"
                               v-model="ticket.partner.sites.is_same"
                               v-on:change="copyAddress"
                        />
                        {{ __('Same as company address') }}
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Country') }} <span class="text-red-400">*</span>
                        <select class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                                name="ticket.partner.sites.country"
                                v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.country'] }"
                                v-model="ticket.partner.sites.country"
                                v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        >
                            <option
                                v-bind:value="country.value"
                                v-for="country in countries"
                            >
                                {{ country.label }}
                            </option>
                        </select>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white"
                           v-if="ticket.partner.sites.country !== 'HU'"
                    >
                        {{ __('County') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.sites.county"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.county'] }"
                               v-bind:placeholder="__('placeholders.county')"
                               v-model="ticket.partner.sites.county"
                               v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white"
                           v-else
                    >
                        {{ __('Postcode') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.sites.postcode"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.postcode'] }"
                               v-bind:placeholder="__('placeholders.postcode')"
                               v-model="ticket.partner.sites.postcode"
                               v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        />
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('City') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.sites.city"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.city'] }"
                               v-bind:placeholder="__('placeholders.city')"
                               v-model="ticket.partner.sites.city"
                               v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        />
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Address line 1') }} <span class="text-red-400">*</span>
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.sites.address_line_1"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.address_line_1'] }"
                               v-bind:placeholder="__('Street name, number')"
                               v-model="ticket.partner.sites.address_line_1"
                               v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        />
                    </label>

                    <label class="block col-span-6 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Address line 2') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.partner.sites.address_line_2"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['partner.sites.address_line_2'] }"
                               v-bind:placeholder="__('Apartment, building, floor, suite')"
                               v-model="ticket.partner.sites.address_line_2"
                               v-on:change="ticket.partner.sites.is_same = false; ticket.partner_site_id = null;"
                        />
                    </label>
                </div>

                <div class="gap-4 grid-cols-12 mb-8 md:border-l md:border-l-4 md:grid md:mb-16 md:pl-4 md:py-2"
                     v-else
                >
                    <h2 class="col-span-12 text-lg text-white">
                        {{ __('Location information (where device is found)') }}
                    </h2>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Country') }}
                        <span class="block font-mono underline">{{ partnerSite.country }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white" v-if="partnerSite.country !== 'HU'">
                        {{ __('County') }}
                        <span class="block font-mono underline">{{ partnerSite.county }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white" v-if="partnerSite.country === 'HU'">
                        {{ __('Postcode') }}
                        <span class="block font-mono underline">{{ partnerSite.postcode }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('City') }}
                        <span class="block font-mono underline">{{ partnerSite.city }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Address line 1') }}
                        <span class="block font-mono underline">{{ partnerSite.address_line_1 }}</span>
                    </label>

                    <label class="block col-span-8 mb-4 md:mb-0 text-sm text-white" v-if="partnerSite.address_line_2">
                        {{ __('Address line 2') }}
                        <span class="block font-mono underline">{{ partnerSite.address_line_2 }}</span>
                    </label>
                </div>

                <div class="gap-4 grid-cols-12 mb-16 md:border-l md:border-l-4 md:grid md:pl-4 md:py-2">
                    <h2 class="col-span-12 text-lg text-white">{{ __('Device information') }}</h2>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Serial number (S/N)') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.serial_number"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['serial_number'] }"
                               v-if="!sn"
                               v-model="ticket.serial_number"
                               v-on:change="searchSn"
                        />
                        <span class="block font-mono underline" v-else>{{ sn.sn }}</span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white"
                    >
                        {{ __('Make') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.make"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['make'] }"
                               v-if="!sn"
                               v-model="ticket.make"
                        />
                        <span class="block font-mono"
                              v-bind:class="{ 'underline' : sn.part_number.make }"
                              v-else
                        >
                            {{ sn.part_number.make ?? '-' }}
                        </span>
                    </label>

                    <label class="block col-span-4 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Model') }}
                        <input class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                               name="ticket.model"
                               type="text"
                               v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['model'] }"
                               v-if="!sn"
                               v-model="ticket.model"
                        />
                        <span class="block font-mono"
                              v-bind:class="{ 'underline' : sn.part_number.model }"
                              v-else
                        >
                            {{ sn.part_number.model ?? '-' }}
                        </span>
                    </label>

                    <label class="block col-span-12 mb-4 md:mb-0 text-sm text-white">
                        {{ __('Issue description') }} <span class="text-red-400">*</span>
                        <textarea class="block mt-1 p-1 rounded text-slate-800 text-sm w-full"
                                  name="ticket.description"
                                  rows="3"
                                  type="text"
                                  v-bind:class="{ 'bg-red-200' : !!requestStore.errors?.['description'] }"
                                  v-model="ticket.description"
                        />
                    </label>
                </div>

                <button class="bg-amber-400 font-bold rounded px-6 py-2.5 text-sm uppercase"
                        type="submit"
                        v-on:click.prevent="submit"
                >
                    {{ __('Send') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import '@vuepic/vue-datepicker/dist/main.css';

    import PartnerRepository from '../../../repositories/PartnerRepository';
    import PartnerSiteRepository from '../../../repositories/PartnerSiteRepository';
    import SerialNumberRepository from '../../../repositories/SerialNumberRepository';
    import TenantRepository from '../../../repositories/TenantRepository';
    import TicketRepository from '../../../repositories/TicketRepository';
    import { useGeoStore } from '../../../stores/geo';
    import { useLocalizationStore } from '../../../stores/localization';
    import { useRequestStore } from '../../../stores/request';

    const __ = inject('__');
    const alerts = ref<object[]>([]);
    const countries = ref([]);
    const geoStore = useGeoStore();
    const loading = ref<Boolean>(false);
    const localizationStore = useLocalizationStore();
    const requestStore = useRequestStore();
    const route = useRoute();
    const partner = ref<object>();
    const partnerId = route.query.partner_id;
    const partnerRepository = PartnerRepository.getInstance();
    const partnerSite = ref<object>();
    const partnerSiteId = route.query.partner_site_id;
    const partnerSiteRepository = PartnerSiteRepository.getInstance();
    const serialNumberId = route.query.sn;
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sn = ref<object>();
    const tenant = ref<object>();
    const tenantId = route.params.tenant;
    const tenantRepository = TenantRepository.getInstance();
    const ticketRepository = TicketRepository.getInstance();

    const ticket = ref <object>({
        description: '',
        make: '',
        model: '',
        partner: {
            company_name: null,
            contact_email: '',
            contact_name: '',
            contact_phone: '',
            country: tenant.value?.country ?? '',
            is_company: false,
            sites: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                country: tenant.value?.country ?? '',
                county: '',
                is_same: false,
                postcode: '',
            },
            postcode: '',
            tax_id: null,
        },
        partner_id: null,
        partner_site_id: null,
        serial_number: '',
        serial_number_id: null,
        tenant_id: tenantId,
    });

    function copyAddress() {
        if (ticket.value.partner.sites.is_same) {
            ticket.value.partner.sites.address_line_1 = ticket.value.partner.address_line_1;
            ticket.value.partner.sites.address_line_2 = ticket.value.partner.address_line_2;
            ticket.value.partner.sites.city = ticket.value.partner.city;
            ticket.value.partner.sites.country = ticket.value.partner.country;
            ticket.value.partner.sites.county = ticket.value.partner.county;
            ticket.value.partner.sites.postcode = ticket.value.partner.postcode;
        }
    }

    async function searchSn() {
        if (!ticket.value.serial_number) {
            return;
        }

        const results = await serialNumberRepository.index({
            filters: {
                search : ticket.value.serial_number,
            },
            include: ['partNumber'],
        });

        if (results.length >= 1) {
            ticket.value.make = results[0].part_number.make;
            ticket.value.model = results[0].part_number.model;
        }
    }

    async function setSiteAddress() {
        ticket.value.partner.sites = await partnerSiteRepository.show(ticket.value.partner_site_id);
    }

    async function submit() {
        await ticketRepository.submit(ticket.value).then(() => {
            ticket.value = {
                description: '',
                make: '',
                model: '',
                partner: {
                    company_name: null,
                    contact_email: '',
                    contact_name: '',
                    contact_phone: '',
                    country: tenant.value?.country ?? '',
                    is_company: false,
                    sites: {
                        address_line_1: '',
                        address_line_2: '',
                        city: '',
                        country: tenant.value?.country ?? '',
                        county: '',
                        is_same: false,
                        postcode: '',
                    },
                    postcode: '',
                    tax_id: null,
                },
                partner_id: partnerId,
                partner_site_id: partnerSiteId,
                serial_number: '',
                serial_number_id: serialNumberId,
                tenant_id: tenantId,
            }
        });
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();
        tenant.value = await tenantRepository.show(tenantId);
        ticket.value.partner.country = tenant.value.country;
        ticket.value.partner.sites.country = tenant.value.country;

        if (serialNumberId) {
            sn.value = await serialNumberRepository.show(serialNumberId, {
                include: ['partNumber', 'site', 'site.partner', 'site.partner.sites'],
            }, {
                validateStatus: null,
            });

            if (sn.value.id) {
                partner.value = sn.value.site?.partner;
                partnerSite.value = sn.value.site;
                ticket.value.partner_id = partner.value?.id;
                ticket.value.partner_site_id = partnerSite.value?.id;
                ticket.value.serial_number_id = serialNumberId;
            } else {
                sn.value = null;
            }
        } else if (partnerSiteId) {
            partnerSite.value = await partnerSiteRepository.show(partnerSiteId, {
                include: ['partner', 'partner.sites'],
            }, {
                validateStatus: null,
            });

            if (partnerSite.value.id) {
                partner.value = partnerSite.value.partner;
                ticket.value.partner_id = partner.value.id;
                ticket.value.partner_site_id = partnerSiteId;
            } else {
                partnerSite.value = null;
            }
        } else if (partnerId) {
            partner.value = await partnerRepository.show(partnerId, {
                include: ['sites'],
            }, {
                validateStatus: null,
            });

            if (partner.value.id) {
                ticket.value.partner_id = partnerId;
            } else {
                partner.value = null;
            }
        }
    });
</script>
