import { defineStore } from 'pinia';

import { useRequestStore } from './request';

export const useSubscriptionsStore = defineStore('subscriptions', () => {
    const requestStore = useRequestStore();

    async function getPaymentIntent() {
        return new Promise((resolve, reject) => {
            requestStore.get('subscriptions/intent')
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    async function getProducts() {
        return new Promise((resolve, reject) => {
            requestStore.get('subscriptions/products')
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    return {
        getPaymentIntent,
        getProducts,
    }
});