<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ __('New contact person') }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router v-bind:to="{ name : 'partners.sites.edit' }">
                    {{ __('Back') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <c-control-text class="mb-10" name="name" required
                            v-bind:invalid="!!requestStore.errors?.name"
                            v-bind:label="__('Name')"
                            v-bind:placeholder="__('placeholders.person_name')"
                            v-model="contact.name"
            />

            <div class="grid lg:grid-cols-2 gap-10">
                <c-control-text name="partner.contact_email"
                                v-bind:invalid="!!requestStore.errors?.email"
                                v-bind:label="__('Email')"
                                v-bind:placeholder="__('placeholders.email')"
                                v-model="contact.email"
                />

                <c-control-text name="partner.contact_phone"
                                v-bind:invalid="!!requestStore.errors?.phone"
                                v-bind:label="__('Phone number')"
                                v-bind:placeholder="__('placeholders.phone')"
                                v-model="contact.phone"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlText,
    } from '@teamfurther/cinderblock';

    import PartnerSiteContactRepository from '../../../../repositories/PartnerSiteContactRepository';
    import { useRequestStore } from '../../../../stores/request';

    const route = useRoute();

    const contact = ref<object>({
        name: '',
        email: '',
        phone: '',
        site_id: route.params.site,
    });

    const partnerSiteContactRepository = PartnerSiteContactRepository.getInstance();
    const requestStore = useRequestStore();

    async function submit() {
        await partnerSiteContactRepository.store(contact.value);
    }
</script>
