<template>
    <div class="container px-1 py-5" dusk="board-header">
        <div class="flex flex-col items-end justify-between mb-10 lg:flex-row lg:items-center">
            <board-selector v-bind:active-board="board"
                            v-if="board"
                            v-on:boards-updated="refreshBoards"
            />

            <div class="flex flex-col mb-5 lg:mb-0 md:flex-row md:items-center w-full">
                <c-control-text
                    class="grow-0 mb-5 md:max-w-[300px] md:min-w-[200px] md:mb-0 md:mr-10"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Start typing...')"
                    v-model="s"
                    v-on:keyup="search"
                />

                <div class="flex items-center justify-between lg:w-auto w-full">
                    <div class="relative">
                        <a class="cursor-pointer duration-500 flex items-center p-1 rounded text-sm transition-all"
                           dusk="filter-button"
                           v-bind:class="{ 'bg-highlight text-white' : Object.keys(filters).length }"
                           v-on:click="openFilters = true"
                        >
                            <c-icon class="h-5 mr-2 stroke-slate-800 w-5" icon="filter"
                                    v-bind:class="{ '!stroke-white' : Object.keys(filters).length }"
                            />
                            {{ __('Filter results') }}
                            <span class="bg-white flex font-bold h-4 items-center justify-center ml-1 rounded-full text-center text-highlight text-xs w-4"
                                  v-if="Object.keys(filters).length"
                            >
                                {{ Object.keys(filters).length }}
                            </span>
                        </a>
                        <div class="absolute bg-white border border-slate-200 hidden md:w-[500px] mt-1 rounded shadow-lg w-[calc(100vw-0.5rem)] z-30"
                             v-bind:class="{ '!block' : openFilters }"
                             v-on-click-outside="closeFilters"
                        >
                            <div class="flex">
                                <nav class="bg-slate-50 border-r border-slate-200 flex flex-col shrink-0 text-sm">
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'partner' }"
                                       v-on:click="openFilter = 'partner'"
                                       dusk="filter-partner-tab"
                                    >
                                        {{ __('Partner') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'created_at' }"
                                       v-on:click="openFilter = 'created_at'"
                                       dusk="filter-created-at-tab"
                                    >
                                        {{ __('Ticket creation date') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'updated_at' }"
                                       v-on:click="openFilter = 'updated_at'"
                                       dusk="filter-updated-at-tab"
                                    >
                                        {{ __('Ticket last modified date') }}
                                    </a>
                                    <a class="cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'assignee' }"
                                       v-on:click="openFilter = 'assignee'"
                                       dusk="filter-assignee-tab"
                                    >
                                        {{ __('Primary assignee') }}
                                    </a>
                                </nav>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'partner' }"
                                >
                                    <c-control-autocomplete class="w-full"
                                                            icon="search"
                                                            label-field="name"
                                                            name="filters.partner"
                                                            v-bind:placeholder="__('Search by partner name or tax ID')"
                                                            v-bind:source-get="partnerGet"
                                                            v-bind:source-search="partnerSearch"
                                                            v-model="filtersPartner"
                                                            dusk="partner-filter-autocomplete"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'created_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.created_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.created_at"
                                                         v-on:change="updateDateFilter('created_at')"
                                                         dusk="created-at-filter-options"
                                        />
                                    </div>
                                    <c-control-date name="filters.created_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.created_at"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'updated_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.updated_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.updated_at"
                                                         v-on:change="updateDateFilter('updated_at')"
                                                         dusk="updated-at-filter-options"
                                        />
                                    </div>
                                    <c-control-date name="filters.updated_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.updated_at"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'assignee' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.assignee"
                                                            avatar-field="avatar"
                                                            label-field="name"
                                                            v-bind:placeholder="__('Search by employee name')"
                                                            v-bind:source-get="employeeGet"
                                                            v-bind:source-search="employeeSearch"
                                                            v-model="filtersEmployee"
                                                            dusk="assignee-filter-autocomplete"
                                    />
                                </div>
                            </div>
                            <div class="border-t border-slate-200 flex justify-end p-2">
                                <c-button class="mr-2 p-1.5 text-error" v-on:click="resetFilters" dusk="reset-filters-button">
                                    {{ __('Reset filters') }}
                                </c-button>
                                <c-button class="bg-highlight p-1.5 shrink-0 text-white" dusk="apply-filters-button" v-on:click="filter">
                                    {{ __('Apply filters') }}
                                </c-button>
                            </div>
                        </div>
                    </div>

                    <router-link class="flex items-center md:ml-10 text-highlight text-sm"
                                 v-bind:to="{ name : 'tickets.archive' }"
                                 v-if="can('tickets.archive.index.all|tickets.archive.index.owned')"
                                 dusk="show-archived-tickets-button"
                    >
                        <c-icon class="h-5 mr-2 stroke-highlight w-5" icon="archive" />
                        {{ __('Show archived tickets') }}
                    </router-link>
                </div>
            </div>

            <div class="flex items-center shrink-0">
                <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                          router
                          dusk="create-ticket-button"
                          v-bind:to="{ name : 'tickets.create', query : { board : board.id } }"
                          v-if="can('tickets.store') && board"
                >
                    + {{ __('Add ticket') }}
                </c-button>
                <a class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                   v-if="!can('tickets.store') && can('boards.lists.store')"
                   v-on:click="addList"
                >
                    + {{ __('New list') }}
                </a>
                <div class="after:absolute after:border-[5px] after:border-transparent after:border-t-white after:content-[''] after:h-0 after:mt-1 after:left-1/2 after:pointer-events-none after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-0 bg-highlight border-l border-indigo-800 cursor-pointer h-9 hover:bg-indigo-700 px-4 py-2.5 relative rounded-br rounded-tr"
                     v-if="can('tickets.store|boards.lists.store')"
                     v-on:click="showAddMenu = !showAddMenu"
                     v-on-click-outside="closeAddMenu"
                >
                    <nav class="absolute bg-white border border-slate-200 flex flex-col min-w-[150px] mt-2 right-0 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
                         v-show="showAddMenu"
                    >
                        <router-link class="flex border-b border-slate-200 hover:bg-slate-50 items-center p-2"
                                     v-bind:to="{ name : 'tickets.create', query : { board : board.id } }"
                                     v-if="can('tickets.store') && board"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus" />{{ __('Add ticket') }}
                        </router-link>
                        <a class="flex hover:bg-slate-50 items-center p-2"
                           v-if="can('boards.lists.store')"
                           v-on:click="addList"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="columns" />
                            {{ __('New list') }}
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-grow px-1" dusk="board-body">
        <ul class="flex flex-col h-full lg:flex-row overflow-auto" v-if="lists.length">
            <li class="cursor-grab lg:w-72 mx-2 pt-2 relative shrink-0" draggable="true"
                v-for="list in lists"
                v-on:dragend="dragEnd($event)"
                v-on:dragenter.prevent="dragging(list, $event)"
                v-on:dragover.prevent
                v-on:dragstart.stop="dragStart(list, 'list', $event)"
                v-on:drop="dragDrop(list, $event)"
            >
                <div class="absolute bg-slate-200 border border-dotted border-highlight h-full hidden rounded top-0 w-full z-10"
                     v-bind:class="{ '!block' : dragType === 'list' && dragEl.id === list.id }"
                />

                <h2 class="mb-1">
                    <span class="border border-transparent flex h-8 items-center justify-center"
                          v-if="!showListRename[list.id]"
                    >
                        {{ list.name }}
                    </span>
                    <input class="bg-white border border-slate-200 h-8 px-2 text-center text-md w-full" type="text"
                           ref="renameInputs"
                           v-else
                           v-model="list.name"
                           v-on:blur="renameList(list)"
                           v-on:focus="$event.target.select()"
                           v-on:keyup.enter="$event.target.blur()"
                    />
                </h2>
                <div class="absolute group/list-actions right-3 top-2" v-if="!showListRename[list.id]">
                    <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-3 rounded w-6"
                          v-if="can('boards.lists.update|boards.lists.destroy')"
                          v-on:click="showListActions = []; showListActions[list.id] = !showListActions[list.id]"
                          v-on-click-outside="closeListActions"
                    >
                        <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-horizontal" />
                    </span>
                    <div class="absolute bg-slate-700 bottom-0 hidden min-w-[200px] overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                         v-bind:class="{ '!block' : showListActions[list.id] }"
                    >
                        <a class="border-b border-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs"
                           v-if="can('boards.lists.update')"
                           v-on:click="showListRename[list.id] = true"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Rename') }}
                        </a>
                        <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs"
                           v-if="can('boards.lists.destroy')"
                           v-on:click="deletedList = list"
                        >
                            <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                        </a>
                    </div>
                </div>
                <ul class="bg-slate-100 border border-dotted border-transparent p-1 rounded"
                    v-bind:class="{ '!bg-slate-200 !border-highlight' : dragType === 'ticket' && dragOver === list }"
                >
                    <li class="bg-white border border-slate-200 cursor-grab last:mb-0 mb-2 p-2 relative rounded select-none shadow-sm" draggable="true"
                        v-if="list.tickets?.length > 0"
                        v-for="(ticket, key) in list.tickets"
                        v-on:dragstart.stop="dragStart(ticket, 'ticket', $event)"
                        v-on:dragend="dragEnd($event)"
                    >
                        <router-link class="flex flex-col" draggable="false"
                                     v-bind:to="{
                                        name : ticket.worksheet
                                                    ? sessionStore.user?.tenant.config.toggle_worksheet_edit_allowed
                                                        ? 'worksheets.edit'
                                                        : 'worksheets.show'
                                                    : 'tickets.edit',
                                        params: {
                                            ticket : !ticket.worksheet ? ticket.id : null,
                                            worksheet : ticket.worksheet?.id
                                        }
                                     }"
                        >
                            <div class="flex justify-between mb-2">
                                <div class="flex items-center">
                                    <span class="mr-4 text-slate-500 text-xs">#{{ ticket.oid }}</span>
                                    <span class="bg-yellow-400 cursor-help mr-2 p-1 relative rounded"
                                          v-if="ticket.worksheet && !ticket.worksheet.signed_at"
                                          v-on:mouseover="tooltipStarted = ticket.id"
                                          v-on:mouseout="tooltipStarted = null"
                                    >
                                        <c-icon class="h-4 fill-white w-4" icon="tool" />
                                        <c-tooltip position="top" v-bind:active="tooltipStarted === ticket.id">
                                            {{ __('Work has been started.') }}
                                        </c-tooltip>
                                    </span>
                                    <span class="flex items-center"
                                          v-if="ticket.worksheet?.signed_at"
                                          v-on:mouseover="tooltipFinished = ticket.id"
                                          v-on:mouseout="tooltipFinished = null"
                                    >
                                        <span class="bg-success cursor-help p-1 relative rounded">
                                            <c-icon class="h-4 fill-white w-4" icon="tool" />
                                            <c-tooltip position="top" v-bind:active="tooltipFinished === ticket.id">
                                                {{ __('Work is finished.') }}
                                            </c-tooltip>
                                        </span>
                                        <span class="font-bold ml-1 text-sm text-success"
                                              v-if="ticket.worksheet.oid"
                                        >
                                            #{{ ticket.worksheet.oid }}
                                        </span>
                                    </span>
                                </div>
                                <span class="text-slate-500 text-xs">
                                    {{
                                        DateTime.fromISO(
                                            ticket.updated_at,
                                            { locale : localizationStore.locale }
                                        ).toRelative()
                                    }}
                                </span>
                            </div>
                            <span class="font-semibold pr-4">{{ ticket.title }}</span>
                            <span class="mt-3 overflow-hidden pr-10 text-ellipsis text-sm text-slate-500 whitespace-nowrap" v-if="ticket.assignee">
                                <img class="h-5 inline mr-2 -mt-1 rounded w-5"
                                     v-bind:src="ticket.assignee.avatar" v-bind:alt="ticket.assignee.name"
                                />
                                {{ ticket.assignee.name }}
                            </span>
                        </router-link>
                        <div class="absolute -bottom-1 group/card-actions right-1">
                            <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                                  v-on:click="showCardActions = []; showCardActions[ticket.id] = !showCardActions[ticket.id]"
                                  v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                                  dusk="ticket-actions"
                            >
                                <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                            </span>
                            <div class="absolute bg-slate-700 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                                 v-bind:class="{ '!block' : showCardActions[ticket.id] }"
                            >
                                <router-link class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                             v-bind:to="{
                                                name : 'worksheets.show',
                                                params: { worksheet: ticket.worksheet.id }
                                             }"
                                             v-if="ticket.worksheet && !sessionStore.user.tenant.config.toggle_worksheet_edit_allowed"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="eye" />{{ __('View') }}
                                </router-link>
                                <router-link class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                             v-bind:to="{
                                                name : 'worksheets.edit',
                                                params: { worksheet: ticket.worksheet.id }
                                             }"
                                             v-if="ticket.worksheet && sessionStore.user.tenant.config.toggle_worksheet_edit_allowed"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                                </router-link>
                                <router-link class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                             v-bind:to="{ name : 'tickets.edit', params: { ticket : ticket.id } }"
                                             v-if="!ticket.worksheet"
                                             dusk="edit-ticket-button"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                                </router-link>

                                <a class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                   v-if="can('tickets.store')"
                                   v-on:click="duplicatedTicket = ticket.id"
                                   dusk="duplicate-ticket-button"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="copy" />{{ __('Duplicate ticket') }}
                                </a>

                                <a class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                   v-if="can('tickets.update.all|tickets.update.owned|tickets.update.assigned')"
                                   v-on:click="movedTicket = ticket.id"
                                   dusk="move-ticket-button"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="columns" />{{ __('Move ticket') }}
                                </a>

                                <a class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                   v-if="can('tickets.update.all|tickets.update.owned|tickets.update.assigned')"
                                   v-on:click="archivedTicket = ticket.id"
                                   dusk="archive-ticket-button"
                                >
                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="archive" />{{ __('Archive') }}
                                </a>
                                <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs whitespace-nowrap"
                                   v-if="can('tickets.destroy.all|tickets.destroy.owned|tickets.destroy.assigned')"
                                   v-on:click="deletedTicket = ticket.id"
                                   dusk="delete-ticket-button"
                                >
                                    <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="bg-slate-50 border border-slate-200 p-2 relative rounded shadow-sm text-center text-sm"
                        v-else
                    >
                        {{ __('There are no tickets in this list.') }}
                    </li>
                </ul>
            </li>
        </ul>
        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no tickets available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-bold text-lg text-slate-500">{{ __('There are no tickets available.') }}</span>
                <router-link class="text-sm text-highlight"
                             v-bind:to="{ name : 'tickets.create' }"
                             v-if="can('tickets.store')"
                >
                    + {{ __('Add ticket') }}
                </router-link>
            </div>
        </div>
    </div>

    <c-modal class="md:w-[450px]" v-bind:open="deletedList !== null" v-bind:title="__('Delete list')" v-on:closeModal="deletedList = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this list?') }}
            </div>
            <div class="flex items-center justify-center mt-5 text-sm" v-if="deletedList?.tickets.length > 0">
                {{ __('Move tickets to another list') }}:
                <select class="bg-slate-50 block border border-slate-200 focus:outline-0 min-w-[150px] ml-2 px-1 rounded"
                        v-model="targetList"
                >
                    <option v-for="list in otherLists(deletedList)" v-bind:value="list.id">
                        {{ list.name }}
                    </option>
                </select>
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedList = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white"
                          v-bind:disabled="deletedList?.tickets.length > 0 && !targetList"
                          v-on:click="delList"
                >
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]" v-bind:open="archivedTicket !== null" v-bind:title="__('Archive ticket')" v-on:closeModal="archivedTicket = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to archive this ticket?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="archivedTicket = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-orange-400 text-white" v-on:click="archiveTicket" dusk="archive-ticket-modal-button">
                    {{ __('Archive') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]" v-bind:open="deletedTicket !== null" v-bind:title="__('Delete ticket')" v-on:closeModal="deletedTicket = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this ticket? In most cases, archiving is the more appropriate action.') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedTicket = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" v-on:click="delTicket" dusk="delete-ticket-modal-button">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]" v-bind:open="duplicatedTicket !== null" v-bind:title="__('Duplicate ticket')" v-on:closeModal="duplicatedTicket = null; targetBoard = null; targetList = null;">
        <template v-slot:body>
            <div class="flex items-center justify-center mt-5 text-sm">
                {{ __('Select board') }}:
                <select class="bg-slate-50 block border border-slate-200 focus:outline-0 min-w-[150px] ml-2 px-1 rounded"
                        v-if="board"
                        v-model="targetBoard"
                        v-on:change="targetList = targetBoard.lists[0]"
                >
                    <option v-bind:value="b"
                            v-for="b in boards"
                    >
                        {{ b.name }}
                    </option>
                </select>
            </div>
            <div class="flex items-center justify-center mt-5 text-sm" v-if="targetBoard">
                {{ __('Select list') }}:
                <select class="bg-slate-50 block border border-slate-200 focus:outline-0 min-w-[150px] ml-2 px-1 rounded"
                        v-if="targetBoard"
                        v-model="targetList"
                >
                    <option v-bind:value="l"
                            v-for="l in targetBoard.lists"
                    >
                        {{ l.name }}
                    </option>
                </select>
            </div>

            <div class="mb-2 mt-10 text-sm">
                {{ __('Select elements to copy') }}:
            </div>
            <c-control-checkbox v-bind:disabled="true"
                                v-bind:options="[{ label: __('General information'), value: 'general_information' }]"
                                v-model="duplicatedElements"
            />
            <c-control-checkbox v-bind:options="[{ label: __('Assignees'), value: 'assignees' }]"
                                v-model="duplicatedElements"
            />
            <c-control-checkbox v-bind:options="[{ label: __('Scheduling'), value: 'scheduling' }]"
                                v-model="duplicatedElements"
            />
            <c-control-checkbox v-bind:options="[{ label: __('Tasks'), value: 'tasks' }]"
                                v-model="duplicatedElements"
            />
            <c-control-checkbox v-bind:options="[{ label: __('Files'), value: 'files' }]"
                                v-model="duplicatedElements"
            />
<!--            <c-control-checkbox v-bind:options="[{ label: __('Communication'), value: 'communication' }]"-->
<!--                                v-model="duplicatedElements"-->
<!--            />-->
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800"
                          v-on:click="duplicatedTicket = null; targetBoard = null; targetList = null;"
                >
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white"
                          v-bind:disabled="!targetBoard || !targetList"
                          v-on:click="duplicateTicket"
                >
                    {{ __('Duplicate ticket') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]" v-bind:open="movedTicket !== null" v-bind:title="__('Move ticket')" v-on:closeModal="movedTicket = null; targetBoard = null; targetList = null;">
        <template v-slot:body>
            <div class="flex items-center justify-center mt-5 text-sm">
                {{ __('Move ticket to another board') }}:
                <select class="bg-slate-50 block border border-slate-200 focus:outline-0 min-w-[150px] ml-2 px-1 rounded"
                        v-if="board"
                        v-model="targetBoard"
                        v-on:change="targetList = targetBoard.lists[0]"
                >
                    <option v-bind:value="b"
                            v-for="b in otherBoards(board)"
                    >
                        {{ b.name }}
                    </option>
                </select>
            </div>
            <div class="flex items-center justify-center mt-5 text-sm" v-if="targetBoard">
                {{ __('Select list') }}:
                <select class="bg-slate-50 block border border-slate-200 focus:outline-0 min-w-[150px] ml-2 px-1 rounded"
                        v-if="targetBoard"
                        v-model="targetList"
                >
                    <option v-bind:value="l"
                            v-for="l in targetBoard.lists"
                    >
                        {{ l.name }}
                    </option>
                </select>
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800"
                          v-on:click="movedTicket = null; targetBoard = null; targetList = null;"
                >
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white"
                          v-bind:disabled="!targetBoard || !targetList"
                          v-on:click="moveTicket"
                >
                    {{ __('Move ticket') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref, watch, watchEffect } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlCheckbox,
        CControlDate,
        CControlRadio,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import BoardRepository from '../../repositories/BoardRepository';
    import BoardListRepository from '../../repositories/BoardListRepository';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import PartnerRepository from '../../repositories/PartnerRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';

    import BoardSelector from '../../components/Tickets/BoardSelector.vue';

    const __ = inject('__');
    const can = inject('can');
    const archivedTicket = ref(null);
    const board = ref<object>();
    let boards = ref<object[]>([]);
    const boardRepository = BoardRepository.getInstance();
    const boardListRepository = BoardListRepository.getInstance();

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const closeListActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/list-actions')
            ) {
                showListActions.value = [];
            }
        }
    ];

    const closeFilters = [
        (e) => {
            if (!e.target.className?.toString().startsWith('dp__')) {
                openFilters.value = false;
            }
        }
    ];

    const deletedList = ref(null);
    const deletedTicket = ref(null);
    const dragEl = ref<object>();
    const dragDest = ref<object>();
    const dragImage = ref();
    const dragOrig = ref<object[]>([]);
    const dragOver = ref<object>();
    const dragPos = ref<number>();
    const dragType = ref<string>('');
    const duplicatedElements = ref([
        'general_information',
    ]);
    const duplicatedTicket = ref(null);
    const employeeRepository = EmployeeRepository.getInstance();
    const filters = ref<object>({});
    const filtersEmployee = ref<object>();
    const filtersPartner = ref<object>();

    const filtersSpeedial = ref<object>({
        created_at : 'anytime',
        options: [
            { label : __('Today'), value : 'today' },
            { label : __('Yesterday'), value : 'yesterday' },
            { label : __('This week'), value : 'this_week' },
            { label : __('Last week'), value : 'last_week' },
            { label : __('This month'), value : 'this_month' },
            { label : __('Last month'), value : 'last_month' },
            { label : __('This year'), value : 'this_year' },
            { label : __('Last year'), value : 'last_year' },
            { label : __('Anytime'), value : 'anytime' },
        ],
        updated_at : 'anytime',
    });

    const lists = ref<object[]>([]);
    const localizationStore = useLocalizationStore();
    const movedTicket = ref(null);
    const openFilter = ref<string>('partner');
    const openFilters = ref<boolean>(false);
    const partnerRepository = PartnerRepository.getInstance();
    const renameInputs = ref([]);
    const route = useRoute();
    const s = ref<string>('');

    const search = debounce(async () => {
        if (s.value) {
            filters.value.search = s.value;
        } else {
            delete filters.value.search;
        }

        await getTickets();
    }, 250);

    const sessionStore = useSessionStore();
    const showAddMenu = ref<boolean>(false);
    const showCardActions = ref<boolean[]>([]);
    const showListActions = ref<boolean[]>([]);
    const showListRename = ref<boolean[]>([]);
    const targetBoard = ref(null);
    const targetList = ref(null);
    const ticketRepository = TicketRepository.getInstance();
    const tooltipStarted = ref();
    const tooltipFinished = ref();

    async function addList() {
        const list = await boardListRepository.store({
            board_id : board.value.id,
            name : __('List #:number', { number : lists.value.length + 1 }),
            order : lists.value.length,
        });

        lists.value.push(list);

        window.location.reload();
    }

    async function archiveTicket() {
        await ticketRepository.update(archivedTicket.value, {
            archived_at : DateTime.now()
        });

        await getTickets();

        archivedTicket.value = null;
    }

    function closeAddMenu() {
        showAddMenu.value = false;
    }

    async function delList() {
        await boardListRepository.destroy(deletedList.value.id, {
            target_id : targetList.value
        });

        deletedList.value = null;
        targetList.value = null;

        window.location.reload();
    }

    async function delTicket() {
        await ticketRepository.destroy(deletedTicket.value);

        await getTickets();

        deletedTicket.value = null;
    }

    async function dragDrop(list, e) {
        if (dragType.value === 'ticket') {
            await dragDropTicket(list, e);
        } else if (dragType.value === 'list') {
            await dragDropList();
        }

        dragEl.value = null;
        dragType.value = '';
    }

    async function dragDropList() {
        if (dragDest.value?.id !== dragEl.value.id) {
            await boardListRepository.reorder({
                lists: lists.value
            });
        }
    }

    async function dragDropTicket(list, e) {
        const ticketId = e.dataTransfer.getData('id');

        let i = {};
        lists.value.forEach((l) => {
            l.tickets.forEach((ticket, key) => {
                if (
                    ticketId === ticket.id &&
                    l.name !== list.name // only remove if dropped on a different list
                ) {
                    i = l.tickets[key];
                    l.tickets.splice(key, 1); // remove from old list
                }
            });
        });

        if (Object.keys(i).length > 0) { // don't add if empty
            list.tickets.push(i); // add to new list

            await ticketRepository.update(ticketId, {
                list_id : list.id
            });
        }
    }

    function dragEnd(e) {
        if (e.dataTransfer.dropEffect === 'none' && dragType.value === 'list') {
            lists.value = dragOrig.value;
        }

        dragImage.value.remove();
        dragEl.value = null;
        dragDest.value = null;
        dragOrig.value = [];
        dragOver.value = null;
        dragPos.value = null;
        dragType.value = '';
    }

    function dragging(list, e) {
        dragOver.value = list;

        if (dragType.value === 'ticket' || !dragEl.value) {
            return;
        }

        const direction = e.clientX < dragPos.value ? 0 : 1;
        dragPos.value = e.clientX;

        lists.value.every((l, key) => {
            if (
                list.id !== dragEl.value.id &&
                dragEl.value.id === l.id
            ) {
                lists.value.splice(key, 1);

                return false;
            }

            return true;
        });

        lists.value.every((l, key) => {
            if (
                list.id !== dragEl.value.id &&
                list.id === l.id
            ) {
                dragDest.value = list;
                lists.value.splice(key + direction, 0, dragEl.value);

                return false;
            }

            return true;
        });
    }

    function dragStart(value, type, e) {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('id', value.id);

        dragEl.value = value;
        dragType.value = type;

        dragImage.value = document.createElement('ul');
        const clone = e.target.cloneNode(true);
        if (dragType.value === 'list') {
            dragOrig.value = JSON.parse(JSON.stringify(lists.value));
            clone.querySelector('.group\\/list-actions').remove();
        }

        if (clone.style) {
            clone.style.listStyle = 'none';
            clone.style.transform = 'rotate(3deg)';
            clone.style.width = e.target.offsetWidth + 'px';
        }

        dragImage.value.style.left = '-9999px';
        dragImage.value.style.position = 'absolute';
        dragImage.value.style.top = '-9999px';
        dragImage.value.appendChild(clone);
        document.body.appendChild(dragImage.value);
        e.dataTransfer.setDragImage(dragImage.value, -100, -100);

        dragPos.value = e.clientX;
    }

    async function duplicateTicket() {
        await ticketRepository.duplicate(duplicatedTicket.value, {
            duplicated_elements : duplicatedElements.value,
            list_id : targetList.value.id
        });

        await getTickets();

        duplicatedTicket.value = null;
        targetBoard.value = null;
        targetList.value = null;
    }

    async function employeeGet(employeeId) {
        if (!employeeId.value) {
            return null;
        }

        return await employeeRepository.show(employeeId.value.id);
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                inactive: 'with',
                search: val,
            }
        });
    }

    async function getBoard() {
        const boardId = route.query.board;

        const params = boardId
            ? { filters: { id : boardId } }
            : { filters: { is_default : 1 } };

        const b = await boardRepository.index(params);

        board.value = b[0];
    }

    async function getLists() {
        const boardLists = await boardListRepository.index({
            filters: {
                board_id: board.value.id,
            },
        });

        lists.value = [];
        boardLists.forEach((list) => {
            lists.value.push({
                id: list.id,
                name: list.name,
                order: list.order,
                tickets: [],
            });
        });
    }

    async function getTickets() {
        if (filtersEmployee.value) {
            filters.value.assignee_id = filtersEmployee.value.id;
        } else {
            delete filters.value.assignee_id;
        }

        if (filtersPartner.value) {
            filters.value.partner_id = filtersPartner.value.id;
        } else {
            delete filters.value.partner_id;
        }

        lists.value.forEach((list) => {
            list.tickets = [];
        });

        const tickets = await ticketRepository.index({
            filters: {
                ...filters.value,
                ...{
                    is_not_archived : 1,
                    'board_id' : board.value.id,
                }
            },
            include: ['assignee', 'worksheet'],
        });

        tickets.forEach((ticket) => {
            const listIndex = lists.value.findIndex(list => list.id === ticket.list_id);

            if (listIndex > -1) {
                lists.value[listIndex].tickets.push(ticket);
            }
        });
    }

    async function filter() {
        await getTickets();

        openFilters.value = false;
    }

    async function moveTicket() {
        await ticketRepository.update(movedTicket.value, {
            list_id : targetList.value.id
        });

        await getTickets();

        movedTicket.value = null;
        targetBoard.value = null;
        targetList.value = null;
    }

    function otherBoards(board) {
        return boards.value.filter(function(b) {
            return b.id !== board.id;
        });
    }

    function otherLists(list) {
        return lists.value.filter(function(l) {
            return l.id !== list.id;
        })
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            }
        });
    }

    function refreshBoards(b) {
        boards.value = b.value;
    }

    async function renameList(list) {
        await boardListRepository.update(list.id, {
            name : list.name
        });

        showListRename.value[list.id] = false;
    }

    async function resetFilters() {
        filters.value = {};
        filtersEmployee.value = null;
        filtersPartner.value = null;
        filtersSpeedial.value.created_at = 'anytime';
        filtersSpeedial.value.updated_at = 'anytime';
        s.value = '';

        openFilters.value = false;

        await getTickets();
    }

    function updateDateFilter(field : string) {
        const dates = (() => {
            switch (filtersSpeedial.value[field]) {
                case 'today':
                    return [
                        DateTime.now(),
                        DateTime.now(),
                    ];
                case 'yesterday':
                    return [
                        DateTime.now().minus({ days: 1}),
                        DateTime.now().minus({ days: 1}),
                    ];
                case 'this_week':
                    return [
                        DateTime.now().startOf('week'),
                        DateTime.now(),
                    ];
                case 'last_week':
                    return [
                        DateTime.now().startOf('week').minus({ days: 7 }),
                        DateTime.now().startOf('week').minus({ days: 1 }),
                    ];
                case 'this_month':
                    return [
                        DateTime.now().startOf('month'),
                        DateTime.now(),
                    ];
                case 'last_month':
                    return [
                        DateTime.now().startOf('month').minus({ month: 1 }),
                        DateTime.now().startOf('month').minus({ days: 1 }),
                    ];
                case 'this_year':
                    return [
                        DateTime.now().startOf('year'),
                        DateTime.now(),
                    ];
                case 'last_year':
                    return [
                        DateTime.now().startOf('year').minus({ year: 1 }),
                        DateTime.now().startOf('year').minus({ days: 1 }),
                    ];
            }
        })();

        if (dates) {
            filters.value[field] = dates;
        } else {
            delete filters.value[field];
        }
    }

    onMounted(async () => {
        if (can('tickets.index.all') === false) {
            const router = useRouter();
            router.push({ name : 'tickets.me' });
        }

        await getBoard();
        await getLists();
        await getTickets();
    });

    watch(() => route.query.board, async () => {
        await getBoard();
        await getLists();
        await getTickets();
    })

    watchEffect(() => {
        renameInputs.value.forEach((input) => {
            input.focus();
        });
    })
</script>
