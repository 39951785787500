import { useSessionStore }  from '../stores/session';

export const can = {
    install: (app, options) => {
        app.config.globalProperties.can = function (value: string): boolean {
            const sessionStore = useSessionStore();

            if (!sessionStore.user || !sessionStore.user['permission_list']) {
                return false;
            }

            const permissions: string[] = sessionStore.user['permission_list'];
            let response = false;

            if (!Array.isArray(permissions)) {
                return false;
            }

            if (value.indexOf('|') >= 0) {
                value.split('|').forEach(function (item: string) {
                    if (permissions.indexOf(item.trim()) >= 0) {
                        response = true;
                    }
                });
            } else if (value.indexOf('&') >= 0) {
                response = true;
                value.split('&').forEach(function (item: string) {
                    if (permissions.indexOf(item.trim()) === -1) {
                        response = false;
                    }
                });
            } else {
                response = permissions.indexOf(value.trim()) >= 0;
            }

            return response;
        };
    },
};