<template>
    <div class="container px-1 py-5">
        <h1 class="lg:text-3xl mb-5 md:mb-10 mr-10 text-2xl">{{ __('Billing') }}</h1>

        <div class="flex flex-col">
            <div class="border inline-flex mb-10 mx-auto relative rounded-full w-[250px]">
                <span class="cursor-pointer duration-500 py-4 relative text-center transition-all w-1/2 z-20"
                      v-bind:class="{ 'text-white' : selectedPrice === 'monthly' }"
                      v-on:click="selectedPrice = 'monthly'"
                >
                    {{ __('Monthly') }}
                </span>
                <span class="cursor-pointer duration-500 py-4 relative text-center transition-all w-1/2 z-20"
                      v-bind:class="{ 'text-white' : selectedPrice === 'yearly' }"
                      v-on:click="selectedPrice = 'yearly'"
                >
                    {{ __('Yearly') }}
                </span>
                <span class="absolute bg-highlight duration-500 h-full rounded-full top-0 transition-all w-1/2 z-10"
                      v-bind:class="{ 'translate-x-full' : selectedPrice === 'yearly' }"
                />
                <div class="absolute hidden items-center md:flex -right-1 -top-2 translate-x-full">
                    <img class="h-4 mr-2" src="/img/subscriptions-save.svg" v-bind:alt="__('Save 10%')" />
                    <span class="font-bold -mt-1 text-highlight text-lg">{{ __('Save 10%') }}</span>
                </div>
            </div>

            <div class="flex flex-col lg:flex-row mb-10" v-if="Object.keys(products).length > 0">
                <div class="bg-white border border-slate-200 cursor-pointer flex items-center lg:w-1/2 p-5 rounded-2xl shadow-lg text-center"
                     v-bind:class="{ '!border-highlight' : selectedProduct === 'essential' }"
                     v-on:click="selectedProduct = 'essential'"
                >
                    <div class="border-r border-slate-200 mr-5 pr-5 w-1/2">
                        <h4 class="font-bold text-lg">Essential</h4>
                    </div>
                    <div class="w-1/2">
                        <span class="text-slate-500 text-sm">
                            <span class="text-lg">
                                {{
                                    currencyStore.format(
                                        products.essential.prices[selectedPrice].amount,
                                        products.essential.prices[selectedPrice].currency
                                    )
                                }}
                            </span>
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                            / {{ __('seat') }}
                        </span>
                    </div>
                </div>
                <div class="bg-white border border-slate-200 cursor-pointer flex items-center lg:w-1/2 lg:ml-4 lg:mt-0 mt-4 p-5 rounded-2xl shadow-lg text-center"
                     v-bind:class="{ '!border-highlight' : selectedProduct === 'extra' }"
                     v-on:click="selectedProduct = 'extra'"
                >
                    <div class="border-r border-slate-200 mr-5 pr-5 w-1/2">
                        <h4 class="font-bold text-lg">Extra</h4>
                    </div>
                    <div class="w-1/2">
                        <span class="text-slate-500 text-sm">
                            <span class="text-lg">
                                {{
                                    currencyStore.format(
                                        products.extra.prices[selectedPrice].amount,
                                        products.extra.prices[selectedPrice].currency
                                    )
                                }}
                            </span>
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                            / {{ __('seat') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col md:flex-row md:items-start">
                <div class="bg-white border border-slate-200 mb-4 md:mr-4 md:w-7/12 p-2 rounded">
                    <h2 class="mb-4">{{ __('Payment method') }}</h2>

                    <StripeElements
                        ref="stripeElements"
                        v-bind:stripe-key="stripePk"
                        v-if="stripeLoaded"
                        v-slot="{ elements, instance }"
                    >
                        <span class="font-semibold text-xs">{{ __('Card number') }}</span>
                            <StripeElement
                                ref="cardElement"
                                type="cardNumber"
                                v-bind:elements="elements"
                                v-bind:options="{ classes: cardClasses, showIcon: true, style: cardStyles }"
                                v-on:blur="showCardNumberRing = false"
                                v-on:focus="showCardNumberRing = true"
                            />
                        <div class="gap-4 grid grid-cols-2 mt-4">
                            <div>
                                <span class="font-semibold text-xs">{{ __('Expiration date') }}</span>
                                <StripeElement
                                    type="cardExpiry"
                                    v-bind:elements="elements"
                                    v-bind:options="{  classes: cardClasses, placeholder: __('MM/YY'), style: cardStyles }"
                                    v-on:blur="showCardExpiryRing = false"
                                    v-on:focus="showCardExpiryRing = true"
                                />
                            </div>
                            <div>
                                <span class="font-semibold text-xs">{{ __('Security code') }}</span>
                                <StripeElement
                                    type="cardCvc"
                                    v-bind:elements="elements"
                                    v-bind:options="{ classes: cardClasses, style: cardStyles }"
                                    v-on:blur="showCardCvvRing = false"
                                    v-on:focus="showCardCvvRing = true"
                                />
                            </div>
                        </div>
                    </StripeElements>

                    <div class="mb-4 mt-10 mx-auto relative lg:w-2/3 md:w-full sm:w-2/3">
                        <img class="relative z-10" src="/img/credit-card.png" />
                        <span class="absolute duration-500 font-mono left-[8%] p-px lg:text-lg rounded text-white top-[50%] transition-all z-20"
                              v-bind:class="{ 'ring-2 ring-amber-500' : showCardNumberRing }"
                        >
                            0000 0000 0000 0000
                        </span>
                        <span class="absolute duration-500 font-mono left-[29%] lg:text-sm p-px rounded text-white text-xs top-[69%] transition-all z-20"
                              v-bind:class="{ 'ring-2 ring-amber-500' : showCardExpiryRing }"
                        >
                            00/00
                        </span>
                        <span class="absolute duration-500 font-mono italic left-[70%] lg:text-sm p-px rounded text-xs top-[49%] transition-all z-20"
                              v-bind:class="{ 'ring-2 ring-amber-500' : showCardCvvRing }"
                        >
                            000
                        </span>
                    </div>
                </div>
                <div class="bg-white border border-slate-200 md:w-5/12 p-2 rounded">
                    <h2 class="mb-10">{{ __('Your summary') }}</h2>

                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Seats') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ seats }}</span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Price per seat') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">
                            {{
                                currencyStore.format(
                                    products[selectedProduct]?.prices[selectedPrice].amount,
                                    products[selectedProduct]?.prices[selectedPrice].currency
                                )
                            }}
                            /
                            <span v-text="selectedPrice === 'monthly' ? __('month') : __('year')"></span>
                        </span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Subscription price') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ currencyStore.format(subscriptionAmount, products[selectedProduct]?.prices[selectedPrice].currency) }}</span>
                    </span>
                    <span class="flex mb-2 text-sm">
                        <span class="shrink-0 text-slate-400">{{ __('Balance') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="shrink-0">{{ currencyStore.format(balanceAmount, products[selectedProduct]?.prices[selectedPrice].currency) }}</span>
                    </span>
                    <span class="flex mt-5 text-sm">
                        <span class="font-semibold shrink-0 text-slate-500">{{ __('Total') }}</span>
                        <span class="border-b border-dashed border-slate-200 bottom-1 mx-2 relative w-full"></span>
                        <span class="font-semibold shrink-0">{{ currencyStore.format(totalAmount, products[selectedProduct]?.prices[selectedPrice].currency) }}</span>
                    </span>

                    <c-button class="bg-success mt-10 text-white w-full"
                              v-bind:disabled="!stripeLoaded || !totalAmount || loading"
                              v-text="
                                !tenant.is_on_generic_trial ?
                                    __('Pay :amount', {
                                        amount: currencyStore.format(totalAmount, products[selectedProduct]?.prices[selectedPrice].currency),
                                    }) :
                                    __('Pay :amount on :date', {
                                        date: DateTime.fromISO(tenant.trial_ends_at).toFormat(localizationStore.dateFormat),
                                        amount: currencyStore.format(totalAmount, products[selectedProduct]?.prices[selectedPrice].currency),
                                    })
                              "
                              v-on:click="subscribe"
                    ></c-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, onBeforeMount, onMounted, ref } from 'vue';
    import { DateTime } from 'luxon';
    import { StripeElements, StripeElement } from 'vue-stripe-js'
    import { loadStripe } from '@stripe/stripe-js';
    import {
        CButton,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import { useCurrencyStore } from '../../stores/currency';
    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';
    import { useSubscriptionsStore }  from '../../stores/subscriptions';

    const __ = inject('__');

    const balanceAmount = computed(() => {
        return subscription.balance;
    });

    const cardClasses = ref({
        base: 'bg-slate-50 p-2 rounded',
    });

    const cardElement = ref(null);

    const cardStyles = ref({
        base: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '16px',
            '::placeholder': {
                color: '#94a3b8',
            },
        },
        invalid: {
            iconColor: '#f87171',
            color: '#fca5a5',
            '::placeholder': {
                color: '#fca5a5',
            },
        }
    });

    const currencyStore = useCurrencyStore();
    const employeeRepository = EmployeeRepository.getInstance();
    const loading = ref<boolean>(false);
    const localizationStore = useLocalizationStore();
    const paymentIntent = ref();
    const products = ref<object>({});
    const requestStore = useRequestStore();
    const seats = ref<number>(1);
    const selectedPrice = ref<string>('yearly');
    const selectedProduct = ref<string>('essential');
    const sessionStore = useSessionStore();

    const subscriptionAmount = computed(() => {
        return seats.value * products.value[selectedProduct.value]?.prices[selectedPrice.value].amount;
    });

    const subscriptionsStore = useSubscriptionsStore();
    const showCardCvvRing = ref<boolean>(false);
    const showCardExpiryRing = ref<boolean>(false);
    const showCardNumberRing = ref<boolean>(false);
    const stripeElements = ref(null);
    const stripeLoaded = ref<boolean>(false);
    const stripePk = import.meta.env.VITE_STRIPE_KEY;
    const stripePromise = loadStripe(stripePk);
    const tenant = sessionStore.user.tenant;
    const subscription = tenant.subscription_plan;

    const totalAmount = computed(() => {
        return subscriptionAmount.value + balanceAmount.value;
    });

    async function subscribe() {
        loading.value = true;

        const { setupIntent, error } = await stripeElements.value.instance.confirmCardSetup(paymentIntent.value.client_secret, {
            payment_method: {
                card: cardElement.value.stripeElement,
                billing_details: {
                    address: {
                        city: tenant.city,
                        country: tenant.country,
                        line1: tenant.address_line_1,
                        line2: tenant.address_line_2,
                        postal_code: tenant.postal_code ?? null,
                        state: tenant.county ?? null,
                    },
                    email: tenant.email,
                    name: tenant.name,
                },
            },
        });

        if (error) {
            console.error(error);

            loading.value = false;
        } else {
            await requestStore.post('subscriptions/subscribe', {
                payment_method: setupIntent.payment_method,
                price: selectedPrice.value,
                product: selectedProduct.value,
            }).then(async () => {
                await sessionStore.refreshUser();

                requestStore.redirect({ name: 'subscriptions.billing' }, {
                    message: __('Subscription status successfully updated.'),
                    status: 200,
                    title: __('Success'),
                });
            }).catch(async () => {
                loading.value = false;

                paymentIntent.value = await subscriptionsStore.getPaymentIntent();
            });
        }
    }

    onBeforeMount(() => {
        stripePromise.then(() => {
            stripeLoaded.value = true;
        });
    });

    onMounted(async () => {
        paymentIntent.value = await subscriptionsStore.getPaymentIntent();
        products.value = await subscriptionsStore.getProducts();

        seats.value = await employeeRepository.index().then((employees) => {
            return employees.length;
        });
    });
</script>