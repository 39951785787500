<template>
    <c-modal class="max-w-[900px]"
             v-bind:open="open"
             v-bind:title="__('New ticket')"
             v-on:closeModal="closeDestination"
    >
        <template v-slot:body>
            <div class="flex flex-col">
                <c-control-text class="mb-10" name="ticket.title"
                                v-bind:label="__('Ticket title')"
                                v-model="ticket.title"
                />

                <label class="mb-1 text-sm" for="ticket.notes">{{ __('Ticket description') }}</label>
                <textarea class="border border-slate-200 focus:border-highlight focus:outline-0 mb-10 p-1 rounded text-sm" id="ticket.description" name="ticket.description" rows="3"
                          v-bind:class="{ 'border-error' : !!requestStore.errors?.description}"
                          v-model="ticket.description"
                />


                <div class="flex flex-col items-center justify-between md:flex-row mb-10">
                    <div class="md:w-1/3 w-full">
                        <c-control-autocomplete class="mb-5" icon="search" label-field="name" name="partner" required
                                                dusk="partner-autocomplete"
                                                v-bind:invalid="!partner && !!requestStore.errors?.partner_site_id"
                                                v-bind:label="__('Partner')"
                                                v-bind:placeholder="__('Search by partner name or tax ID')"
                                                v-bind:source-get="partnerGet"
                                                v-bind:source-search="partnerSearch"
                                                v-model="partner"
                        />

                        <c-control-select class="mb-5" name="ticket.site" required
                                          v-bind:invalid="!!requestStore.errors?.partner_site_id"
                                          v-bind:label="__('Site')"
                                          v-bind:options="sites"
                                          v-if="partner"
                                          v-model="ticket.partner_site_id"
                        />
                    </div>

                    <span class="text-slate-500 text-sm">{{ __('or') }}</span>

                    <c-button class="bg-slate-500 md:w-1/3 shrink-0 text-white w-full"
                              dusk="create-new-partner"
                              v-on:click="showPartnerForm"
                    >
                        {{ __('New partner') }}
                    </c-button>
                </div>

                <div class="bg-slate-50 p-4" v-if="ticket.partner.new">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Partner details') }}</h2>
                    <div class="grid lg:grid-cols-3 gap-10 mb-10">
                        <c-control-text class="lg:col-span-2" name="partner.name" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.name']"
                                        v-bind:label="__('Name')"
                                        v-bind:placeholder="__('placeholders.company_name')"
                                        v-model="ticket.partner.name" />

                        <c-control-text name="partner.tax_id"
                                        v-bind:invalid="!!requestStore.errors?.['partner.tax_id']"
                                        v-bind:label="__('Tax ID')"
                                        v-bind:placeholder="__('placeholders.tax_id')"
                                        v-model="ticket.partner.tax_id" />
                    </div>

                    <div class="grid lg:grid-cols-3 gap-10 mb-10">
                        <c-control-select name="country" required
                                          v-bind:invalid="!!requestStore.errors?.['partner.country']"
                                          v-bind:label="__('Country')"
                                          v-bind:options="countries"
                                          v-model="ticket.partner.country"
                        />
                        <c-control-text name="postcode" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.postcode']"
                                        v-bind:label="__('Postcode')"
                                        v-bind:placeholder="__('placeholders.postcode')"
                                        v-if="ticket.partner.country === 'HU'"
                                        v-model="ticket.partner.postcode"
                        />

                        <c-control-text name="county" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.county']"
                                        v-bind:label="__('County')"
                                        v-bind:placeholder="__('placeholders.county')"
                                        v-else
                                        v-model="ticket.partner.county"
                        />

                        <c-control-text name="city" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.city']"
                                        v-bind:label="__('City')"
                                        v-bind:placeholder="__('placeholders.city')"
                                        v-model="ticket.partner.city"
                        />
                    </div>
                    <div class="grid lg:grid-cols-2 gap-10 mb-10">
                        <c-control-text name="address_line_1" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.address_line_1']"
                                        v-bind:label="__('Address line 1')"
                                        v-bind:placeholder="__('Street name, number')"
                                        v-model="ticket.partner.address_line_1"
                        />

                        <c-control-text name="address_line_2"
                                        v-bind:invalid="!!requestStore.errors?.['partner.address_line_2']"
                                        v-bind:label="__('Address line 2')"
                                        v-bind:placeholder="__('Apartment, building, floor, suite')"
                                        v-model="ticket.partner.address_line_2"
                        />
                    </div>

                    <h2 class="font-medium mb-5 text-lg">{{ __('Contact person') }}</h2>
                    <div class="grid lg:grid-cols-3 gap-10 mb-10">
                        <c-control-text name="partner.contact_name" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.contact_name']"
                                        v-bind:label="__('Name')"
                                        v-bind:placeholder="__('placeholders.person_name')"
                                        v-model="ticket.partner.contact_name" />

                        <c-control-text name="partner.contact_email"
                                        v-bind:invalid="!!requestStore.errors?.['partner.contact_email']"
                                        v-bind:label="__('Email')"
                                        v-bind:placeholder="__('placeholders.email')"
                                        v-model="ticket.partner.contact_email" />

                        <c-control-text name="partner.contact_phone"
                                        v-bind:invalid="!!requestStore.errors?.['partner.contact_phone']"
                                        v-bind:label="__('Phone number')"
                                        v-bind:placeholder="__('placeholders.phone')"
                                        v-model="ticket.partner.contact_phone" />
                    </div>

                    <h2 class="font-medium mb-5 text-lg">{{ __('Partner sites') }}</h2>

                    <c-control-text class="mb-10" name="partner.sites.name" required
                                    v-bind:invalid="!!requestStore.errors?.['partner.sites.name']"
                                    v-bind:label="__('Site name')"
                                    v-model="ticket.partner.sites.name"
                    />

                    <div class="grid lg:grid-cols-3 gap-10 mb-10">
                        <c-control-select name="partner.sites.country" required
                                          v-bind:invalid="!!requestStore.errors?.['partner.sites.country']"
                                          v-bind:label="__('Country')"
                                          v-bind:options="countries"
                                          v-model="ticket.partner.sites.country"
                        />

                        <c-control-text name="partner.sites.postcode" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.sites.postcode']"
                                        v-bind:label="__('Postcode')"
                                        v-bind:placeholder="__('placeholders.postcode')"
                                        v-if="ticket.partner.sites.country === 'HU'"
                                        v-model="ticket.partner.sites.postcode"
                        />

                        <c-control-text name="partner.sites.county" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.sites.county']"
                                        v-bind:label="__('County')"
                                        v-bind:placeholder="__('placeholders.county')"
                                        v-else
                                        v-model="ticket.partner.sites.county"
                        />

                        <c-control-text name="partner.sites.city" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.sites.city']"
                                        v-bind:label="__('City')"
                                        v-bind:placeholder="__('placeholders.city')"
                                        v-model="ticket.partner.sites.city"
                        />
                    </div>
                    <div class="grid lg:grid-cols-2 gap-10 mb-10">
                        <c-control-text name="partner.sites.address_line_1" required
                                        v-bind:invalid="!!requestStore.errors?.['partner.sites.address_line_1']"
                                        v-bind:label="__('Address line 1')"
                                        v-bind:placeholder="__('Street name, number')"
                                        v-model="ticket.partner.sites.address_line_1"
                        />

                        <c-control-text name="partner.sites.address_line_2"
                                        v-bind:label="__('Address line 2')"
                                        v-bind:invalid="!!requestStore.errors?.['partner.sites.address_line_2']"
                                        v-bind:placeholder="__('Apartment, building, floor, suite')"
                                        v-model="ticket.partner.sites.address_line_2"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" dusk="cancel-button" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import {
        CButton,
        CControlAutocomplete,
        CControlSelect,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../repositories/PartnerRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';
    import { useGeoStore } from '../../stores/geo';

    const countries = ref([]);
    const geoStore = useGeoStore();
    const open = ref<boolean>(true);
    const partner = ref<object>(null);
    const partnerRepository = PartnerRepository.getInstance();
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const sessionStore = useSessionStore();
    const sites = ref<Object[]>();
    const ticket = ref<object>({
        partner: {
            address_line_1 : '',
            address_line_2 : '',
            city : '',
            contact_email: '',
            contact_name: '',
            contact_phone: '',
            country : JSON.parse(window.localStorage.getItem('user')).tenant.country,
            county: '',
            name: '',
            postcode : '',
            sites: {
                name: '',
                country : JSON.parse(window.localStorage.getItem('user')).tenant.country,
                postcode : '',
                county: '',
                city : '',
                address_line_1 : '',
                address_line_2 : '',
            },
            tax_id: '',
        }
    });
    const ticketRepository = TicketRepository.getInstance();

    function closeDestination() {
        open.value = false;

        if (!window.history.state.back) {
            router.push({ name : 'tickets.me' });
        } else {
            router.go(-1);
        }
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            },
            include: ['sites']
        });
    }

    function showPartnerForm() {
        partner.value = null;
        ticket.value.partner.new = true;
        ticket.value.partner_site_id = null;
    }

    async function submit() {
        await ticketRepository.store({
            ... {
                board_id : route.query.board ?? null,
            },
            ...ticket.value
        });
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();
    });

    watch(() => partner.value, () => {
        if (partner.value !== null) {
            ticket.value.partner.new = false;
        }

        sites.value = partner.value?.sites.map((site) => {
            return {
                label : site['name'],
                value : site['id'],
            }
        });
    });
</script>
